@import '../../variables'

.polis-home
  align-items: flex-end
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  min-height: 8291px
  overflow-x: hidden
  position: relative
  width: 1440px

.overlap-group-container-2
  height: 1711px
  margin-right: -4px
  position: relative
  width: 1444px

.overlap-group7-3
  align-items: center
  background-color: $zircon
  display: flex
  flex-direction: column
  left: 0
  min-height: 865px
  padding: 124.5px 132.7px
  position: absolute
  top: 846px
  width: 1440px

.sub-headings-124
  letter-spacing: 1.60px
  line-height: 18px
  margin-right: 1.0px
  min-height: 18px
  text-align: center
  white-space: nowrap
  width: 167px

.sub-headings-125
  letter-spacing: 0
  line-height: 50px
  margin-right: 0.67px
  margin-top: 18px
  min-height: 100px
  text-align: center
  width: 527px

.group-39498
  align-items: flex-start
  align-self: flex-start
  display: flex
  margin-top: 59px
  min-width: 1173px

.service-1
  align-items: flex-start
  align-self: flex-end
  border: 1px none
  display: flex
  height: 420px
  min-width: 374px

.overlap-group-20
  align-items: center
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  flex-direction: column
  min-height: 420px
  padding: 15.4px 26.3px
  width: 374px

.group-38028-3
  background-color: $carnation-2
  border-radius: 15.46px
  height: 89px
  margin-right: 2.0px
  margin-top: 50px
  width: 86px

.sub-headings-126
  letter-spacing: 0
  line-height: 34px
  margin-right: 3.0px
  margin-top: 24px
  min-height: 34px
  min-width: 202px
  text-align: center
  white-space: nowrap

.overlap-group1-11
  align-self: flex-start
  height: 180px
  margin-top: 12px
  position: relative
  width: 321px

.sub-headings-127
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  text-align: center
  top: 0
  width: 321px

.line-451-1
  height: 1px
  left: 106px
  position: absolute
  top: 155px
  width: 110px

.service-2
  align-items: flex-end
  border: 1px none
  display: flex
  height: 406px
  justify-content: flex-end
  margin-left: 26px
  margin-top: 0.29px
  min-width: 374px

.group-container-14
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  flex-direction: column
  margin-bottom: -13.49px
  min-height: 420px
  padding: 22.5px 26.5px
  width: 374px

.group-38028-4
  align-self: center
  background-color: $gold-2
  border-radius: 15.46px
  height: 86px
  margin-right: 2.15px
  margin-top: 41px
  width: 86px

.overlap-group1-10
  height: 224px
  margin-top: 24px
  position: relative
  width: 321px

.sub-headings-119
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  text-align: center
  top: 44px
  width: 321px

.sub-headings-128
  left: 33px
  letter-spacing: 0
  line-height: 34px
  position: absolute
  text-align: center
  top: 0

.line-451-2
  height: 1px
  left: 101px
  position: absolute
  top: 207px
  width: 110px

.service-3
  align-items: flex-end
  border: 1px none
  display: flex
  height: 406px
  margin-left: 26px
  min-width: 374px

.group-container-15
  align-items: flex-end
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  flex-direction: column
  margin-bottom: -13.6px
  min-height: 420px
  padding: 22.8px 26.3px
  width: 374px

.group-38028-5
  align-self: center
  background-color: $torea-bay-3
  border-radius: 15.46px
  height: 86px
  margin-right: 2.0px
  margin-top: 40px
  width: 86px

.sub-headings-129
  left: 41px
  letter-spacing: 0
  line-height: 34px
  position: absolute
  text-align: center
  top: 0

.line-451-3
  height: 1px
  left: 106px
  position: absolute
  top: 207px
  width: 110px

.overlap-group-container-3
  height: 846px
  left: 133px
  position: absolute
  top: 0
  width: 1307px

.overlap-group8-2
  height: 846px
  left: 566px
  position: absolute
  top: 0
  width: 741px

.rectangle-1512-1
  background-color: $torea-bay
  border: 1px none
  height: 846px
  left: 187px
  position: absolute
  top: 0
  width: 554px

.ellipse-114-2
  height: 187px
  left: 0
  position: absolute
  top: 659px
  width: 375px

.rectangle-1513-2
  background-color: $catalina-blue
  border: 1px none
  height: 284px
  left: 187px
  position: absolute
  top: 0
  width: 284px

.rectangle-4050
  background-color: $catalina-blue
  border: 1px none
  height: 284px
  left: 457px
  position: absolute
  top: 562px
  width: 284px

.rectangle-1514-1
  height: 269px
  left: 472px
  position: absolute
  top: 0
  width: 269px

.overlap-group1-12
  height: 557px
  left: 41px
  position: absolute
  top: 145px
  width: 565px

.rectangle-4049
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 18.54px
  box-shadow: 0px 18px 54px #14132a12
  height: 557px
  left: 63px
  position: absolute
  top: 0
  width: 502px

.ellipse-429
  background-color: $carnation
  border: 1px none
  border-radius: 7px
  height: 14px
  left: 87px
  position: absolute
  top: 21px
  width: 14px

.ellipse-430
  background-color: $gold
  border: 1px none
  border-radius: 7px
  height: 14px
  left: 109px
  position: absolute
  top: 21px
  width: 14px

.ellipse-431
  background-color: $torea-bay
  border: 1px none
  border-radius: 7px
  height: 14px
  left: 131px
  position: absolute
  top: 21px
  width: 14px

.rectangle-4409
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 13px
  height: 199px
  left: 87px
  position: absolute
  top: 58px
  width: 454px

.group-39566
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 13px
  display: flex
  flex-direction: column
  left: 87px
  min-height: 255px
  padding: 21.4px 18.4px
  position: absolute
  top: 274px
  width: 253px

.vector-container
  height: 166px
  position: relative
  width: 216px

.vector-15-1
  height: 166px
  left: 6px
  position: absolute
  top: 0
  width: 204px

.vector-16-1
  height: 114px
  left: 0
  position: absolute
  top: 40px
  width: 216px

.group-39562
  align-items: center
  display: flex
  margin-left: 1.66px
  margin-top: 25px
  min-width: 130px

.ellipse-432
  background-color: $torea-bay
  border: 1px none
  border-radius: 6.58px
  height: 13px
  width: 13px

.rectangle-4432
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 5px
  margin-bottom: 0
  margin-left: 4px
  opacity: 0.5
  width: 52px

.ellipse-433
  background-color: $gold
  border: 1px none
  border-radius: 6.58px
  height: 13px
  margin-left: 16px
  width: 13px

.rectangle-4433
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 5px
  margin-bottom: 0
  margin-left: 4px
  opacity: 0.5
  width: 29px

.group-39567
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 13px
  display: flex
  flex-direction: column
  left: 356px
  min-height: 255px
  padding: 28.2px 26.0px
  position: absolute
  top: 274px
  width: 185px

.group-39555
  align-items: flex-start
  align-self: center
  display: flex
  height: 113px
  margin-right: 2.0px
  min-width: 113px

.ellipse-container
  border-radius: 62.63px
  height: 125px
  margin-left: -6px
  margin-top: -6.36px
  position: relative
  width: 125px

.ellipse-49
  border: 12.72px solid
  border-color: $torea-bay
  border-radius: 62.63px
  height: 125px
  left: 0
  position: absolute
  top: 0
  width: 125px

.ellipse-50
  border: 12.72px solid
  border-color: $gold
  border-radius: 62.63px
  height: 125px
  left: 0
  position: absolute
  top: 0
  width: 125px

.ellipse-48
  border: 12.72px solid
  border-color: $carnation
  border-radius: 62.63px
  height: 125px
  left: 0
  position: absolute
  top: 0
  width: 125px

.group-39559
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 17px
  min-height: 67px
  width: 131px

.group-39552
  align-items: center
  display: flex
  min-width: 111px

.ellipse-432-1
  background-color: $torea-bay
  border: 1px none
  border-radius: 8.51px
  height: 17px
  width: 17px

.rectangle-4432-1
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 7px
  margin-bottom: 0
  margin-left: 5px
  opacity: 0.5
  width: 90px

.group-39551
  align-items: center
  display: flex
  margin-top: 8px
  min-width: 72px

.ellipse-433-1
  background-color: $carnation
  border: 1px none
  border-radius: 8.51px
  height: 17px
  width: 17px

.rectangle-4433-1
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 7px
  margin-bottom: 0
  margin-left: 5px
  opacity: 0.5
  width: 50px

.group-39550
  align-items: center
  display: flex
  margin-top: 8px
  min-width: 131px

.ellipse-434
  background-color: $gold
  border: 1px none
  border-radius: 8.51px
  height: 17px
  width: 17px

.rectangle-4434
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 7px
  margin-bottom: 0
  margin-left: 7px
  opacity: 0.5
  width: 107px

.group-39553
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 113px
  min-height: 139px
  position: absolute
  top: 88px
  width: 400px

.group-39549
  height: 109px
  margin-left: 1.69px
  width: 398px

.group-39554
  align-items: flex-start
  display: flex
  margin-top: 17px
  min-width: 226px

.group-39552-1
  align-items: center
  display: flex
  min-width: 69px

.group-39551-1
  align-items: center
  display: flex
  margin-left: 16px
  min-width: 46px

.ellipse-433-2
  background-color: $carnation
  border: 1px none
  border-radius: 6.58px
  height: 13px
  width: 13px

.group-39550-1
  align-items: center
  display: flex
  margin-left: 16px
  min-width: 80px

.ellipse-434-1
  background-color: $gold
  border: 1px none
  border-radius: 6.58px
  height: 13px
  width: 13px

.rectangle-4434-1
  background-color: $snuff
  border: 1px none
  border-radius: 30px
  height: 5px
  margin-bottom: 0
  margin-left: 5px
  opacity: 0.5
  width: 62px

.group-39569
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 18.54px
  box-shadow: 0px 18px 54px #14132a12
  display: flex
  height: 238px
  left: 0
  min-width: 238px
  padding: 25.2px 23.1px
  position: absolute
  top: 206px

.circle-small-container
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 181px
  position: relative
  width: 54px

.avatar-mike-warren-circle-small
  align-items: flex-end
  background-size: 100% 100%
  border: 0.9px none
  display: flex
  justify-content: flex-end
  margin-left: 0
  min-width: 52px

.mask-group-13
  height: 52px
  margin-bottom: -0.01px
  width: 52px

.group-container-16
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-bottom: 7.6px
  margin-left: 9px
  min-height: 153px
  width: 90px

.group-4233
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 26px
  width: 90px

.rectangle-1283
  background-color: $gray-suit
  border: 1.53px none
  border-radius: 45.88px
  height: 9px
  opacity: 0.8
  width: 53px

.rectangle-1284
  background-color: $snuff
  border: 1.53px none
  border-radius: 45.88px
  height: 9px
  margin-left: 0
  margin-top: 7px
  opacity: 0.4
  width: 90px

.group-4234
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 36px
  min-height: 26px
  width: 90px

.rectangle-1283-1
  background-color: $gray-suit
  border: 1.53px none
  border-radius: 45.88px
  height: 9px
  opacity: 0.8
  width: 90px

.rectangle-1284-1
  background-color: $snuff
  border: 1.53px none
  border-radius: 45.88px
  height: 9px
  margin-left: 0
  margin-top: 7px
  opacity: 0.4
  width: 53px

.group-4235
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 40px
  min-height: 26px
  width: 90px

.rectangle-1283-2
  background-color: $gray-suit
  border: 1.53px none
  border-radius: 45.88px
  height: 9px
  opacity: 0.8
  width: 31px

.overlap-group9-3
  height: 484px
  left: 0
  position: absolute
  top: 145px
  width: 606px

.group-38091
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 0
  min-height: 484px
  position: absolute
  top: 0
  width: 606px

.sub-headings-130
  letter-spacing: 1.60px
  line-height: 18px
  margin-left: 0.35px
  margin-top: -1px
  min-height: 18px
  white-space: nowrap

.sub-headings-131
  letter-spacing: 0
  line-height: 60px
  margin-left: 0.35px
  margin-top: 21px
  min-height: 180px
  width: 600px

.sub-headings-132
  letter-spacing: 0
  line-height: 30px
  margin-left: 0.35px
  margin-top: 27px
  min-height: 120px
  width: 523px

.frame-35997
  border: 1px none
  height: 64px
  margin-top: 55px
  width: 269px

.primary-btn-36
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  left: 0
  min-width: 269px
  padding: 21px 40px
  position: absolute
  top: 388px

.button-text-36
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 189px
  text-align: center
  white-space: nowrap

.about-us
  align-items: center
  align-self: center
  display: flex
  flex-direction: column
  margin-left: 7.23px
  margin-top: 121px
  min-height: 948px
  width: 1181px

.flex-row-25
  align-items: flex-start
  display: flex
  height: 138px
  margin-right: 1.98px
  min-width: 1179px

.group-38147
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 138px
  width: 389px

.sub-headings-120
  letter-spacing: 1.60px
  line-height: 18px
  margin-top: -1px
  min-height: 18px
  white-space: nowrap

.sub-headings-133
  letter-spacing: 0
  line-height: 50px
  margin-top: 20px
  min-height: 100px
  width: 385px

.sub-headings-134
  letter-spacing: 0
  line-height: 30px
  margin-left: 265px
  margin-top: 8px
  min-height: 90px
  width: 525px

.mask-group-container
  height: 618px
  margin-right: 7.27px
  margin-top: 61px
  position: relative
  width: 1174px

.mask-group-14
  height: 540px
  left: 0
  position: absolute
  top: 0
  width: 674px

.mask-group-15
  height: 545px
  left: 474px
  position: absolute
  top: 73px
  width: 700px

.primary-btn-37
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-right: 15.73px
  margin-top: 67px
  min-width: 173px
  padding: 21px 40px

.button-text-37
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 93px
  text-align: center
  white-space: nowrap

.overlap-group13-1
  height: 2074px
  margin-right: -168.7px
  margin-top: 119px
  position: relative
  width: 1609px

.rectangle-4051-2
  background-color: $zircon
  border: 1px none
  height: 1038px
  left: 0
  position: absolute
  top: 0
  width: 1440px

.group-38147-1
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 133px
  min-height: 188px
  position: absolute
  top: 127px
  width: 531px

.sub-headings-135
  letter-spacing: 0
  line-height: 50px
  margin-top: 20px
  min-height: 150px
  width: 527px

.frame-35998
  align-items: flex-start
  border: 1px none
  display: flex
  height: 64px
  left: 893px
  min-width: 411px
  position: absolute
  top: 251px

.group-39448
  align-items: flex-start
  display: flex
  height: 504px
  left: 133px
  min-width: 1174px
  position: absolute
  top: 389px

.group-container-17
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 504px
  position: relative
  width: 574px

.group-39307
  align-items: center
  background-color: $white
  border: 1px solid
  border-color: $seashell
  border-radius: 24px
  box-shadow: 0px 2.15px 10.77px #1e245914
  display: flex
  height: 240px
  justify-content: flex-end
  min-width: 574px
  padding: 24px 37px

.group-38028-6
  background-color: $carnation-2
  border-radius: 15.46px
  height: 86px
  margin-bottom: 42.0px
  width: 86px

.group-39305
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  margin-left: 26px
  min-height: 164px
  width: 387px

.sub-headings-136
  letter-spacing: 0
  line-height: 34px
  margin-top: -1px
  min-height: 34px
  white-space: nowrap

.sub-headings-137
  letter-spacing: 0
  line-height: 30px
  margin-top: 10px
  min-height: 120px
  width: 383px

.group-container-18
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 26px
  min-height: 504px
  position: relative
  width: 574px

.overlap-group11-1
  height: 1038px
  left: 0
  position: absolute
  top: 1036px
  width: 1607px

.rectangle-4052
  background-color: $catalina-blue
  border: 1px none
  height: 1038px
  left: 0
  position: absolute
  top: 0
  width: 1440px

.group-39600
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 135px
  min-height: 128px
  position: absolute
  top: 121px
  width: 531px

.sub-headings-138
  letter-spacing: 0
  line-height: 50px
  margin-top: 10px
  min-height: 100px
  width: 527px

.testimonials
  align-items: flex-start
  background-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  height: 414px
  justify-content: flex-end
  left: 133px
  min-width: 474px
  padding: 73.2px 40px
  position: absolute
  top: 289px

.overlap-group1-13
  height: 266px
  position: relative
  width: 386px

.group-37846
  align-items: center
  display: flex
  height: 62px
  left: 0
  min-width: 163px
  position: absolute
  top: 204px

.mask-group-12
  height: 62px
  width: 62px

.sub-headings-container-3
  height: 44px
  margin-bottom: 2.0px
  margin-left: 12px
  position: relative
  width: 89px

.sub-headings-121
  left: 0
  letter-spacing: 0
  line-height: 20px
  position: absolute
  top: 0

.sub-headings-122
  left: 0
  letter-spacing: 0
  line-height: 20px
  position: absolute
  top: 24px
  white-space: nowrap

.sub-headings-139
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 0
  width: 386px

.group-37885
  align-items: flex-start
  background-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  flex-direction: column
  left: 633px
  min-height: 414px
  padding: 73.4px 40px
  position: absolute
  top: 289px
  width: 474px

.sub-headings-140
  align-self: flex-end
  letter-spacing: 0
  line-height: 34px
  min-height: 170px
  min-width: 386px

.group-37846-1
  align-items: center
  display: flex
  margin-left: 8px
  margin-top: 34px
  min-width: 176px

.sub-headings-container-4
  height: 44px
  margin-bottom: 2.0px
  margin-left: 12px
  position: relative
  width: 102px

.group-37886
  align-items: flex-start
  background-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  flex-direction: column
  left: 1133px
  min-height: 414px
  padding: 73.4px 45px
  position: absolute
  top: 289px
  width: 474px

.sub-headings-141
  align-self: flex-end
  letter-spacing: 0
  line-height: 34px
  min-height: 170px
  min-width: 381px

.group-37846-2
  align-items: center
  display: flex
  margin-left: 3px
  margin-top: 34px
  min-width: 188px

.sub-headings-container-5
  height: 44px
  margin-bottom: 2.0px
  margin-left: 12px
  position: relative
  width: 114px

.sub-headings-142
  left: 780px
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 176px
  width: 527px

.educators
  align-items: center
  align-self: center
  display: flex
  margin-right: 2.0px
  margin-top: 127px
  min-width: 1172px

.group-39349-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 58.5px
  min-height: 368px
  width: 527px

.group-39343-1
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 252px
  width: 533px

.sub-headings-143
  letter-spacing: 0
  line-height: 50px
  margin-top: 20px
  min-height: 100px
  width: 527px

.sub-headings-144
  letter-spacing: 0
  line-height: 30px
  margin-top: 24px
  min-height: 90px
  width: 527px

.primary-btn-38
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 1px
  margin-top: 52px
  min-width: 246px
  padding: 21px 40px

.button-text-38
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 166px
  text-align: center
  white-space: nowrap

.group-container-19
  height: 685px
  margin-left: 55px
  position: relative
  width: 590px

.mask-group-16
  height: 625px
  left: 65px
  position: absolute
  top: 0
  width: 525px

.group-39344
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $seashell
  border-radius: 24px
  box-shadow: 0px 2.15px 10.77px #1e245914
  display: flex
  flex-direction: column
  left: 0
  min-height: 277px
  padding: 49px 48px
  position: absolute
  top: 408px
  width: 430px

.sub-headings-145
  letter-spacing: 0
  line-height: 30px
  min-height: 90px
  width: 333px

.group-39192
  align-items: center
  display: flex
  margin-left: 0.37px
  margin-top: 19px
  min-width: 284px

.mask-group-17
  height: 68px
  width: 68px

.flex-col-13
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 2.0px
  margin-left: 19px
  min-height: 50px
  width: 193px

.sub-headings-146,
.sub-headings-151,
.describe-your-project-1
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.ceo-at-marketingly-x
  letter-spacing: 0
  line-height: 20px
  margin-top: 10px
  min-height: 20px
  white-space: nowrap

.resources
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-right: -270.98px
  margin-top: 127px
  min-height: 611px
  width: 1578px

.flex-row-26
  align-items: flex-end
  display: flex
  height: 138px
  margin-top: -1px
  min-width: 1128px
  position: relative

.sub-headings-container-6
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 138px
  width: 444px

.sub-headings-147
  letter-spacing: 1.60px
  line-height: 18px
  min-height: 18px
  white-space: nowrap

.sub-headings-148
  letter-spacing: 0
  line-height: 50px
  margin-left: 0.27px
  margin-top: 20px
  min-height: 100px
  width: 444px

.secondary-btn-3
  align-items: flex-start
  border: 1px solid
  border-color: $torea-bay
  border-radius: 10px
  display: flex
  height: 64px
  margin-bottom: 11px
  margin-left: 22px
  min-width: 160px
  padding: 21px 40px

.button-text-39
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 80px
  text-align: center
  white-space: nowrap

.flex-row-27
  align-items: flex-start
  display: flex
  margin-top: 49px
  min-width: 1574px
  position: relative

.overlap-group5-4
  height: 425px
  margin-left: 26px
  position: relative
  width: 400px

.contact-form-1
  align-items: center
  background-color: $zircon
  display: flex
  justify-content: flex-end
  margin-top: 177px
  min-width: 1440px

.group-39523-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 58.0px
  min-height: 398px
  width: 480px

.sub-headings-container-7
  height: 226px
  margin-top: 20px
  position: relative
  width: 474px

.sub-headings-149
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 136px
  width: 444px

.sub-headings-150
  left: 0
  letter-spacing: 0
  line-height: 50px
  position: absolute
  top: 0
  width: 474px

.overlap-group3-6
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 97px
  margin-left: 0.02px
  margin-top: 38px
  min-width: 300px
  padding: 26px 22.0px

.group-39141
  align-self: center
  height: 28px
  margin-bottom: 1.0px
  width: 35px

.sub-headings-container-8
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 22px
  min-height: 42px
  width: 181px

.sub-headings-152
  letter-spacing: 0
  line-height: 18px
  margin-top: 4px
  min-height: 18px
  white-space: nowrap

.overlap-group4-6
  align-self: flex-end
  height: 900px
  margin-bottom: 0
  margin-left: 92px
  position: relative
  width: 735px

.rectangle-4097-2
  background-color: $torea-bay
  border: 1px none
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 900px
  left: 274px
  position: absolute
  top: 0
  width: 461px

.rectangle-4032-2
  height: 290px
  left: 445px
  position: absolute
  top: 610px
  width: 290px

.ellipse-407-1
  height: 318px
  left: 415px
  position: absolute
  top: 0
  width: 318px

.group-38113-1
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 0
  min-height: 739px
  padding: 62.9px 0
  position: absolute
  top: 80px
  width: 600px

.group-37882-1
  align-items: flex-start
  display: flex
  height: 342px
  margin-right: 0
  min-width: 490px

.group-container-20
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 342px
  position: relative
  width: 232px

.group-37940-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 94px
  width: 232px

.sub-headings-123
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.group-3-2
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 13px
  min-width: 230px
  padding: 19px 20.7px

.select-service-1
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap
  width: 111px

.vector-26
  align-self: center
  height: 5px
  margin-bottom: 2.0px
  margin-left: 65px
  width: 11px

.group-container-21
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 28px
  min-height: 342px
  position: relative
  width: 232px

.group-37939-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 0
  margin-top: 30px
  min-height: 94px
  width: 232px

.budget-1
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap
  width: 57px

.vector-27
  align-self: center
  height: 5px
  margin-bottom: 2.0px
  margin-left: 119px
  width: 11px

.overlap-group1-14
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 147px
  margin-top: 30px
  min-width: 490px
  padding: 15.3px 24px

