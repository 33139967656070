@import '../../variables'

.primary-btn-31
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 1px
  margin-top: 46px
  min-width: 189px
  padding: 21px 40px

.button-text-33
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 109px
  text-align: center
  white-space: nowrap

.primary-btn-31.primary-btn-32
  margin-left: unset
  margin-top: 49px

