@import './variables'

.lato-medium-shadow-blue-18px
  color: $shadow-blue
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500

.lato-bold-mirage-24px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-xxl
  font-style: normal
  font-weight: 700

.lato-bold-torea-bay-18px
  color: $torea-bay
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 700

.lato-medium-torea-bay-18px
  color: $torea-bay
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500

.lato-bold-mirage-38px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-xxxl
  font-style: normal
  font-weight: 700

.lato-bold-mirage-18px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 700

.lato-bold-white-18px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 700

.lato-bold-mirage-22px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-xl
  font-style: normal
  font-weight: 700

.lato-bold-mirage-16px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-s
  font-style: normal
  font-weight: 700

.lato-bold-white-24px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-xxl
  font-style: normal
  font-weight: 700

.literata-bold-gray-suit-16px
  color: $gray-suit
  font-family: $font-family-literata
  font-size: $font-size-s
  font-style: normal
  font-weight: 700

.lato-medium-torea-bay-16px
  color: $torea-bay
  font-family: $font-family-lato
  font-size: $font-size-s
  font-style: normal
  font-weight: 500

.lato-bold-mirage-54px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-xxxxl
  font-style: normal
  font-weight: 700

.lato-bold-white-38px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-xxxl
  font-style: normal
  font-weight: 700

.lato-medium-athens-gray-18px
  color: $athens-gray
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500

.lato-medium-mirage-18px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500

.lato-normal-shadow-blue-16px
  color: $shadow-blue
  font-family: $font-family-lato
  font-size: $font-size-s
  font-style: normal
  font-weight: 400

.lato-bold-catalina-blue-40px
  color: $catalina-blue
  font-family: $font-family-lato
  font-size: 40px
  font-style: normal
  font-weight: 700

.lato-bold-white-54px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-xxxxl
  font-style: normal
  font-weight: 700

.lato-medium-shadow-blue-16px
  color: $shadow-blue
  font-family: $font-family-lato
  font-size: $font-size-s
  font-style: normal
  font-weight: 500

.lato-medium-mirage-20px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-l
  font-style: normal
  font-weight: 500

.lato-bold-shadow-blue-18px
  color: $shadow-blue
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 700

.lato-bold-mirage-20px
  color: $mirage
  font-family: $font-family-lato
  font-size: $font-size-l
  font-style: normal
  font-weight: 700

.lato-bold-zircon-54px
  color: $zircon
  font-family: $font-family-lato
  font-size: $font-size-xxxxl
  font-style: normal
  font-weight: 700

.lato-medium-white-16px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-s
  font-style: normal
  font-weight: 500

.lato-medium-torea-bay-24px
  color: $torea-bay
  font-family: $font-family-lato
  font-size: $font-size-xxl
  font-style: normal
  font-weight: 500

.literata-bold-shadow-blue-16px
  color: $shadow-blue
  font-family: $font-family-literata
  font-size: $font-size-s
  font-style: normal
  font-weight: 700

.lato-medium-white-18px
  color: $white
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500

.lato-bold-mirage-46px
  color: $mirage
  font-family: $font-family-lato
  font-size: 46px
  font-style: normal
  font-weight: 700

.lato-medium-snuff-18px
  color: $snuff
  font-family: $font-family-lato
  font-size: $font-size-m
  font-style: normal
  font-weight: 500