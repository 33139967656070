@import '../../variables'

.team
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 3346px
  overflow: hidden
  position: relative
  width: 1440px

.overlap-group2
  height: 1677px
  margin-left: 5.98px
  position: relative
  width: 1446px

.header
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  left: 0
  min-height: 99px
  padding: 4px 0
  position: absolute
  top: 0
  width: 1440px

.flex-row
  align-items: center
  display: flex
  margin-right: 1.0px
  margin-top: 14px
  min-width: 1173px

.image-391
  height: 59px
  object-fit: cover
  width: 110px

.navbar
  align-items: center
  border: 1px none
  display: flex
  margin-bottom: 1.0px
  margin-left: 436px
  min-width: 627px

.frame-36812
  align-items: flex-start
  border: 1px none
  display: flex
  height: 40px
  min-width: 70px
  padding: 9px 10px

.sub-headings
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.navbar-link-sub-headings
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 110px
  white-space: nowrap

.navbar-link-sub-headings-1
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 79px
  white-space: nowrap

.navbar-link-sub-headings-2
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  white-space: nowrap
  width: 36px

.navbar-link-sub-headings-3
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 36px
  white-space: nowrap

.primary-btn
  align-items: flex-start
  background-color: $gold
  border: 1px none
  border-radius: 8px
  display: flex
  height: 46px
  margin-left: 32px
  min-width: 136px
  padding: 13px 24px

.button-text
  letter-spacing: 0
  line-height: 18px
  min-height: 18px
  min-width: 88px
  text-align: center
  white-space: nowrap

.line-478
  height: 1px
  margin-top: 17px
  width: 1440px

.overlap-group1
  align-items: flex-end
  background-color: $catalina-blue
  display: flex
  height: 470px
  left: 0
  min-width: 1442px
  position: absolute
  top: 94px

.e-container
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: -0.48px
  min-height: 470px
  width: 205px

.rectangle-4108
  height: 205px
  width: 205px

.ellipse-412
  height: 264px
  margin-left: 0
  margin-top: 1px
  width: 131px

.flex-col
  align-items: center
  align-self: center
  display: flex
  flex-direction: column
  margin-bottom: 56.94px
  margin-left: 248px
  min-height: 132px
  width: 533px

.title
  letter-spacing: 0
  line-height: 61px
  margin-left: 0
  min-height: 61px
  min-width: 128px
  text-align: center
  white-space: nowrap

.sub-headings-3
  letter-spacing: 0
  line-height: 30px
  margin-top: 11px
  min-height: 60px
  text-align: center
  width: 533px

.e-container-1
  height: 470px
  margin-bottom: -0.1px
  margin-left: 191px
  position: relative
  width: 264px

.ellipse-413
  background-color: $torea-bay-2
  border: 0.77px none
  border-radius: 131.45px/131.84px
  height: 264px
  left: 0
  position: absolute
  top: 207px
  transform: rotate(90.00deg)
  width: 263px

.rectangle-4114
  background-color: $torea-bay
  border: 0.62px none
  height: 208px
  left: 56px
  position: absolute
  top: 0
  width: 208px

.ellipse-419
  background-color: $gold
  border: 1px none
  border-radius: 102.9px
  height: 206px
  left: 56px
  position: absolute
  top: 0
  width: 206px

.were-looking-to-add
  letter-spacing: 0
  line-height: 34px
  margin-top: 59px
  min-height: 68px
  text-align: center
  width: 404px

.lorem-ipsum-dolor-si
  letter-spacing: 0
  line-height: 30px
  margin-top: 13px
  min-height: 60px
  text-align: center
  width: 491px

.footer
  align-items: center
  border: 1px none
  display: flex
  flex-direction: column
  margin-top: 182px
  min-height: 592px
  padding: 1px 0
  width: 1440px

.line-443
  height: 1px
  margin-right: 0.04px
  width: 1440px

.flex-row-1
  align-items: flex-start
  display: flex
  height: 335px
  margin-top: 79px
  min-width: 1174px

.group-container
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 328px
  width: 369px

.group-39615
  align-items: flex-start
  display: flex
  height: 231px
  margin-left: 0.1px
  min-width: 369px

.flex-col-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: -1px
  min-height: 232px
  width: 186px

.sub-headings-4
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  min-height: 20px
  white-space: nowrap

.group-39614
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 40px
  min-height: 172px
  width: 186px

.sub-headings-1
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.sub-headings-2
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  margin-top: 24px
  min-height: 20px
  white-space: nowrap

.sub-headings-5
  letter-spacing: 0
  line-height: 20px
  margin-top: 24px
  min-height: 40px
  width: 81px

.group-39613
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-bottom: 4.8px
  margin-left: 40px
  min-height: 108px
  width: 147px

.group-2793
  align-items: flex-start
  display: flex
  margin-top: 68px
  min-width: 255px
  position: relative

.group-2787
  align-items: flex-start
  background-color: $blue
  border-radius: 80px
  display: flex
  height: 29px
  justify-content: flex-end
  min-width: 29px
  padding: 6.4px 10.0px

.icon-facebook
  height: 16px
  width: 9px

.group-78
  align-items: flex-end
  background-color: $navy-blue
  border-radius: 80px
  display: flex
  height: 29px
  margin-left: 16px
  min-width: 29px
  padding: 7.4px 7.1px

.icon-linkedin
  height: 14px
  width: 15px

.group-39616
  align-items: flex-start
  background-color: $torea-bay
  background-size: 100% 100%
  border-radius: 13px
  display: flex
  flex-direction: column
  margin-left: 348px
  min-height: 335px
  padding: 54.9px 45.0px
  width: 457px

.subscribe-to-our-newsletter
  letter-spacing: 0
  line-height: 34px
  margin-bottom: 0
  min-height: 34px
  white-space: nowrap

.overlap-group
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 44px
  min-width: 364px
  padding: 19.0px 24px

.primary-btn-1
  align-items: flex-start
  background-color: $white
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 0.34px
  margin-top: 22px
  min-width: 364px
  padding: 21px 143px

.button-text-1
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 78px
  text-align: center
  white-space: nowrap

.line-444
  height: 1px
  margin-right: 0.32px
  margin-top: 70px
  width: 1174px

.copyright-marketin
  align-self: flex-start
  letter-spacing: 0
  line-height: 30px
  margin-bottom: 0
  margin-left: 133px
  margin-top: 32px
  min-height: 30px
  white-space: nowrap

