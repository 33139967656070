@import '../../variables'

.blog-v1-card-2
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 533px
  min-height: 425px
  position: absolute
  top: 569px
  width: 374px

.overlap-group3
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38095
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 28px
  min-height: 145px
  position: relative
  width: 376px

.what-is-growth-hacki
  letter-spacing: 0
  line-height: 34px
  margin-top: 16px
  min-height: 68px
  width: 374px

.blog-v1-card-2.blog-v1-card-2-1
  align-self: center
  left: unset
  margin-left: 26px
  margin-top: 46.54px
  position: unset
  top: unset

.blog-v1-card-2.blog-v1-card-2-2
  top: 1289px

.blog-v1-card-2.blog-v1-card-2-3
  left: unset
  margin-left: 26px
  position: unset
  top: unset

