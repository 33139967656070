.avatar-sophie-moore-circle-small
  align-items: flex-start
  background-size: 100% 100%
  border: 0.92px none
  display: flex
  justify-content: flex-end
  margin-top: 9px
  min-width: 54px

.mask-group-18
  height: 54px
  margin-top: 0
  width: 54px

.avatar-sophie-moore-circle-small.avatar-andy-smith-circle-small
  align-items: flex-end
  border: 0.93px none
  margin-left: 0
  margin-top: 12px

.avatar-sophie-moore-circle-small.avatar-andy-smith-circle-small .mask-group-18
  margin-bottom: 0
  margin-top: unset

