@import '../../variables'

.group-75
  align-items: flex-end
  background-color: $azure-radiance
  border-radius: 80px
  display: flex
  height: 29px
  margin-left: 16px
  min-width: 29px
  padding: 8.1px 6.7px

.vector
  height: 13px
  width: 15px

.group-75.group-77,
.group-75.group-77-1,
.group-75.group-77-2,
.group-75.group-77-3,
.group-75.group-77-4,
.group-75.group-77-5,
.group-75.group-77-6,
.group-75.group-77-7,
.group-75.group-77-8
  align-self: flex-end
  background-color: $red
  padding: 8.4px 6.1px

.group-75.group-77 .vector,
.group-75.group-77-1 .vector,
.group-75.group-77-2 .vector,
.group-75.group-77-3 .vector,
.group-75.group-77-4 .vector,
.group-75.group-77-5 .vector,
.group-75.group-77-6 .vector,
.group-75.group-77-7 .vector,
.group-75.group-77-8 .vector
  height: 12px
  width: 17px

