@import '../../variables'

.blog-v1-card-6
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 933px
  min-height: 425px
  position: absolute
  top: 1056px
  width: 374px

.overlap-group6
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38099
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 28px
  min-height: 147px
  position: relative
  width: 376px

.address-3
  letter-spacing: 0
  line-height: 34px
  margin-top: 16px
  min-height: 68px
  width: 374px

