@import '../../variables'

.header-1
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  margin-left: 0.82px
  min-height: 99px
  padding: 4px 0
  width: 1440px

.flex-row-3
  align-items: center
  display: flex
  margin-right: 1.0px
  margin-top: 14px
  min-width: 1173px

.image-391-1
  height: 59px
  object-fit: cover
  width: 110px

.navbar-1
  align-items: center
  border: 1px none
  display: flex
  margin-bottom: 1.0px
  margin-left: 436px
  min-width: 627px

.frame-36812-1
  align-items: flex-start
  border: 1px none
  display: flex
  height: 40px
  min-width: 70px
  padding: 9px 10px

.sub-headings-9
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.navbar-link-sub-headings-4
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 110px
  white-space: nowrap

.navbar-link-sub-headings-5
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 79px
  white-space: nowrap

.navbar-link-sub-headings-6
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  white-space: nowrap
  width: 36px

.navbar-link-sub-headings-7
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 32px
  min-height: 20px
  min-width: 36px
  white-space: nowrap

.primary-btn-6
  align-items: flex-start
  background-color: $gold
  border: 1px none
  border-radius: 8px
  display: flex
  height: 46px
  margin-left: 32px
  min-width: 136px
  padding: 13px 24px

.button-text-6
  letter-spacing: 0
  line-height: 18px
  min-height: 18px
  min-width: 88px
  text-align: center
  white-space: nowrap

.line-478-1
  height: 1px
  margin-top: 17px
  width: 1440px

.header-1.header-2
  margin-left: 0.88px

.header-1.header-3
  left: 155px
  margin-left: unset
  position: absolute
  top: 0

.header-1.header-4,
.header-1.header-5,
.header-1.header-7
  left: 0
  margin-left: unset
  position: absolute
  top: 0

.header-1.header-6
  left: 202px
  margin-left: unset
  position: absolute
  top: 68px

.header-1.header-8
  margin-left: unset

