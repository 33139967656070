@import '../../variables'

.group-37931
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 94px
  width: 232px

.sub-headings-115
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.overlap-group-19
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 13px
  min-width: 230px
  padding: 19px 21.1px

.sub-headings-116
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.group-37931.group-37930,
.group-37931.group-37930-1
  margin-left: 0
  margin-top: 30px

.group-37931.group-37930 .overlap-group-19,
.group-37931.group-37930-1 .overlap-group-19
  padding: 20.5px 21.1px

