.frame-35996-13
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-left: 0.04px
  margin-top: 29px
  min-width: 110px

.sub-headings-21
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap
  width: 92px

.group
  align-self: center
  height: 10px
  margin-left: 13px
  margin-top: 0
  width: 5px

.frame-35996-13.frame-35996-1
  margin-left: unset
  margin-top: 22px

.frame-35996-13.frame-35996-2
  margin-left: unset
  margin-top: 20px

.frame-35996-13.frame-35996-4,
.frame-35996-13.frame-35996-7,
.frame-35996-13.frame-35996-10
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-top: 24px
  min-width: 110px

.frame-35996-13.frame-35996-5,
.frame-35996-13.frame-35996-8,
.frame-35996-13.frame-35996-11
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-top: 22px
  min-width: 110px

.frame-35996-13.frame-35996-5 .group,
.frame-35996-13.frame-35996-11 .group,
.frame-35996-13.frame-35996-6 .group,
.frame-35996-13.frame-35996-7 .group,
.frame-35996-13.frame-35996-12 .group,
.frame-35996-13.frame-35996-3-1 .group,
.frame-35996-13.frame-35996-3 .group
  margin-top: unset

.frame-35996-13.frame-35996-6,
.frame-35996-13.frame-35996-9,
.frame-35996-13.frame-35996-12
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-top: 20px
  min-width: 110px

.frame-35996-13.frame-35996-3,
.frame-35996-13.frame-35996-3-1
  margin-left: unset
  margin-top: 24px

