@import '../../variables'

.group-37933
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 94px
  width: 232px

.sub-headings-111
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.overlap-group-17
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 13px
  min-width: 230px
  padding: 19px

.sub-headings-112
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.group-37933.group-37941,
.group-37933.group-37941-1
  margin-top: 30px

