@import '../../variables'

.cta
  align-items: flex-end
  background-color: $torea-bay
  border: 1px none
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  height: 297px
  justify-content: flex-end
  left: 133px
  min-width: 1174px
  position: absolute
  top: 0

.overlap-group7
  height: 297px
  margin-bottom: -0.04px
  position: relative
  width: 1174px

.rectangle-4032
  height: 148px
  left: 1026px
  position: absolute
  top: 149px
  width: 148px

.mask-group-1
  height: 297px
  left: 0
  position: absolute
  top: 0
  width: 1174px

.group-38107
  align-items: flex-start
  display: flex
  height: 100px
  left: 74px
  min-width: 1028px
  position: absolute
  top: 99px

.subscribe-to-our-newsletter-1
  letter-spacing: 0
  line-height: 50px
  margin-top: -1px
  min-height: 100px
  width: 338px

.overlap-group-4
  align-items: flex-start
  align-self: center
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  box-shadow: 0px 3px 12px #0000000d
  display: flex
  height: 80px
  justify-content: flex-end
  margin-bottom: 0.72px
  margin-left: 133px
  min-width: 555px
  padding: 7px 10.0px

.sub-headings-25
  align-self: center
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 4.0px
  min-height: 20px
  min-width: 197px
  white-space: nowrap

.primary-btn-14
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 156px
  min-width: 163px
  padding: 21px 40px

.button-text-14
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 83px
  text-align: center
  white-space: nowrap

