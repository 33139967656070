.group-39122
  align-items: flex-start
  display: flex
  height: 143px
  margin-left: 9.0px
  margin-top: 137px
  min-width: 636px

.group-2068
  align-items: flex-start
  align-self: flex-end
  display: flex
  flex-direction: column
  min-height: 137px
  width: 591px

.heading-h2-2
  letter-spacing: 0
  line-height: 28px
  margin-top: -1px
  min-height: 28px
  white-space: nowrap

.paragraph
  letter-spacing: -0.16px
  line-height: 32px
  margin-top: 13px
  min-height: 96px
  width: 587px

.group-39115
  height: 40px
  margin-left: 5px
  width: 40px

.group-39122.group-39122-1
  margin-top: 94px

.group-39122.group-39122-2
  margin-top: 117px

