@import '../../variables'

.group-73
  align-items: flex-end
  align-self: center
  background: radial-gradient(50% 50% at 50% 50%, rgb(250, 143, 33) 9%, rgb(234.81, 36.2, 130.28) 78%)
  border-radius: 80px
  display: flex
  height: 29px
  margin-left: 16px
  margin-top: 0.04px
  min-width: 29px
  padding: 5.9px 5.8px

.icon-instagram
  height: 17px
  width: 17px

.group-73.group-79,
.group-73.group-79-1,
.group-73.group-79-2,
.group-73.group-79-3,
.group-73.group-79-4,
.group-73.group-79-5,
.group-73.group-79-6,
.group-73.group-79-7,
.group-73.group-79-8
  align-items: flex-start
  align-self: unset
  background: unset
  background-color: $apple
  margin-top: unset
  padding: 5.1px 6.0px

