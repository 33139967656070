$apple: #53e447
$athens-gray: #eff0f6
$azure-radiance: #009eff
$black: #000000
$blue: #1781fd
$carnation: #ff6262
$carnation-2: #ff62621a
$catalina-blue: #0d2c6f
$gold: #ffd600
$gold-2: #ffd6001a
$gray-suit: #bbbed1
$mirage: #14132a
$mist-gray: #c4c4c4
$mystic: #e7e8f1
$navy-blue: #157de6
$red: #ff0000
$seashell: #f1f1f1
$shadow-blue: #8083a2
$snuff: #dbddea
$torea-bay: #103da0
$torea-bay-2: #132999
$torea-bay-3: #103da01a
$white: #ffffff
$zircon: #f7fbff
$font-size-l: 20px
$font-size-m: 18px
$font-size-s: 16px
$font-size-xl: 22px
$font-size-xxl: 24px
$font-size-xxxl: 38px
$font-size-xxxxl: 54px
$font-family-lato: "Lato", Helvetica
$font-family-literata: "Literata", Helvetica