@import '../../variables'

.polis-team-member
  align-items: flex-start
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 2465px
  overflow: hidden
  position: relative
  width: 1440px

.overlap-group4-4
  height: 1873px
  margin-left: -0.02px
  position: relative
  width: 1440px

.rectangle-4452
  background-color: $torea-bay
  border: 1px none
  height: 922px
  left: 0
  position: absolute
  top: 94px
  width: 469px

.ellipse-114
  background-color: $gold
  border: 1px none
  border-radius: 194.83px
  height: 390px
  left: 0
  position: absolute
  top: 821px
  width: 390px

.rectangle-3862
  background-color: $catalina-blue
  border: 1px none
  height: 277px
  left: 0
  position: absolute
  top: 94px
  width: 277px

.rectangle-4108-1
  height: 217px
  left: 469px
  position: absolute
  top: 94px
  width: 217px

.rectangle-4051-1
  background-color: $zircon
  border: 1px none
  height: 857px
  left: 0
  position: absolute
  top: 1016px
  width: 1440px

.vector-21
  height: 1px
  left: 684px
  position: absolute
  top: 66px
  width: 64px

.group-39626
  align-items: center
  display: flex
  height: 693px
  left: 186px
  min-width: 1068px
  position: absolute
  top: 208px

.mask-group-7
  height: 693px
  width: 500px

.group-39625
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 68px
  min-height: 495px
  width: 500px

.group-39624
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 92px
  width: 270px

.sub-headings-90
  letter-spacing: 0
  line-height: 50px
  margin-top: -1px
  min-height: 50px
  white-space: nowrap

.sub-headings-91
  letter-spacing: 0
  line-height: 38px
  margin-top: 4px
  min-height: 38px
  white-space: nowrap

.line-448
  height: 1px
  margin-top: 56px
  width: 500px

.group-39623
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 55px
  min-height: 291px
  width: 500px

.group-38084
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 199px
  width: 500px

.sub-headings-92
  letter-spacing: 0
  line-height: 34px
  margin-top: -1px
  min-height: 34px
  white-space: nowrap

.sub-headings-93
  letter-spacing: 0
  line-height: 30px
  margin-top: 14px
  min-height: 151px
  width: 500px

.group-2793-10
  align-items: flex-start
  display: flex
  height: 38px
  margin-top: 54px
  min-width: 200px

.group-2787-10
  align-items: flex-start
  background-color: $blue
  border-radius: 136.35px
  box-shadow: 0px 10.45px 14.37px #1574ef36
  display: flex
  height: 37px
  justify-content: flex-end
  margin-top: 0
  min-width: 37px
  padding: 8.2px 12.9px

.icon-facebook-10
  height: 21px
  width: 11px

.group-75-3
  align-items: flex-end
  background-color: $azure-radiance
  border-radius: 136.35px
  box-shadow: 0px 10.45px 14.37px #1abaff45
  display: flex
  height: 37px
  margin-left: 17px
  min-width: 37px
  padding: 10.5px 8.6px

.vector-20
  height: 16px
  width: 20px

.group-73-2
  align-items: flex-end
  align-self: flex-end
  background: radial-gradient(50% 50% at 50% 50%, rgb(250, 143, 33) 9%, rgb(234.81, 36.2, 130.28) 78%)
  border-radius: 136.35px
  box-shadow: 0px 10.45px 14.37px #fa0e5a24
  display: flex
  height: 37px
  margin-bottom: 0
  margin-left: 17px
  min-width: 37px
  padding: 7.6px 7.5px

.vector-22
  height: 22px
  width: 22px

.group-78-4
  align-items: flex-end
  background-color: $navy-blue
  border-radius: 136.35px
  box-shadow: 0px 10.45px 14.37px #147ee757
  display: flex
  height: 37px
  margin-left: 17px
  min-width: 37px
  padding: 9.5px 9.1px

.icon-linkedin-10
  height: 18px
  width: 19px

.group-39558
  align-items: flex-start
  display: flex
  height: 537px
  left: 133px
  min-width: 1174px
  position: absolute
  top: 1175px

.articles-by-michael
  letter-spacing: 0
  line-height: 50px
  margin-top: 6.83px
  min-height: 50px
  min-width: 322px
  white-space: nowrap

.primary-btn-29
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 80px
  display: flex
  height: 64px
  margin-left: 622px
  min-width: 225px
  padding: 21px 40px

.button-text-31
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 145px
  text-align: center
  white-space: nowrap

