@import '../../variables'

.polis-blog
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 3797px
  overflow: hidden
  position: relative
  width: 1440px

.flex-row-2
  align-items: center
  display: flex
  height: 132px
  margin-right: 13.38px
  margin-top: 108px
  min-width: 1161px

.blog-title-goes-here
  letter-spacing: 0
  line-height: 61px
  min-height: 132px
  width: 649px

.sub-headings-6
  letter-spacing: 0
  line-height: 30px
  margin-left: 55px
  margin-top: 0
  min-height: 89px
  width: 457px

.overlap-group8
  height: 433px
  margin-right: 0.05px
  margin-top: 36px
  position: relative
  width: 1174px

.rectangle-4047
  background-color: $mist-gray
  border: 1px none
  border-radius: 24px
  height: 433px
  left: 0
  position: absolute
  top: 0
  width: 649px

.featured-post-1
  align-items: flex-start
  display: flex
  height: 433px
  left: 0
  min-width: 1174px
  position: absolute
  top: 0

.mask-group
  height: 433px
  margin-top: 0
  width: 649px

.group-38093
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-bottom: 0.22px
  margin-left: 55px
  min-height: 296px
  position: relative
  width: 474px

.overlap-group-1
  height: 211px
  margin-top: 16px
  position: relative
  width: 470px

.what-is-personal-education
  left: 0
  letter-spacing: 0
  line-height: 50px
  position: absolute
  top: 0
  width: 470px

.sub-headings-7
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 122px
  width: 457px

.featured-post-1-1
  align-items: flex-start
  display: flex
  margin-left: 0.41px
  margin-top: 56px
  min-width: 1174px

.group-38093-1
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-left: 55px
  margin-top: 1.3px
  min-height: 247px
  position: relative
  width: 474px

.why-we-founded-polis
  letter-spacing: 0
  line-height: 50px
  margin-top: 17px
  min-height: 72px
  width: 470px

.sub-headings-8
  letter-spacing: 0
  line-height: 30px
  min-height: 89px
  width: 457px

.overlap-group9
  height: 2382px
  margin-left: 0.02px
  margin-top: 118px
  position: relative
  width: 1440px

.rectangle-4054
  background-color: $zircon
  border: 1px none
  height: 1620px
  left: 0
  position: absolute
  top: 170px
  width: 1440px

.latest-posts
  left: 133px
  letter-spacing: 0
  line-height: 50px
  position: absolute
  top: 458px
  white-space: nowrap

.group-38112
  align-items: flex-start
  display: flex
  height: 64px
  left: 574px
  min-width: 294px
  position: absolute
  top: 1566px

.primary-btn-4
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 10px
  box-shadow: 0px 4px 4px #0000000d
  display: flex
  height: 64px
  min-width: 150px
  padding: 21px 40px

.button-text-4
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 70px
  text-align: center
  white-space: nowrap

.primary-btn-5
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 24px
  min-width: 120px
  padding: 21px 40px

.button-text-5
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 40px
  text-align: center
  white-space: nowrap

