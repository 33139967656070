@import '../../variables'

.secondary-btn-1
  align-items: flex-start
  border: 1px solid
  border-color: $torea-bay
  border-radius: 10px
  display: flex
  height: 64px
  margin-right: 11px
  min-width: 209px
  padding: 21px 40px

.button-text-24
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 129px
  text-align: center
  white-space: nowrap

.secondary-btn-1.secondary-btn-2
  margin-left: 24px
  margin-right: unset
  min-width: 208px

.secondary-btn-1.secondary-btn-2 .button-text-24
  min-width: 128px

