@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Lato:400,700,500|Literata:700";
@import "https://px.animaapp.com/6315f36fa6fbd0d14fab4909.6315f370d90b3a5e2f762690.lJD3gvf.rsp.png";
.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

.lato-medium-shadow-blue-18px {
  color: #8083a2;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.lato-bold-mirage-24px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-torea-bay-18px {
  color: #103da0;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-torea-bay-18px {
  color: #103da0;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.lato-bold-mirage-38px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-mirage-18px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-white-18px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-mirage-22px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-mirage-16px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-white-24px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.literata-bold-gray-suit-16px {
  color: #bbbed1;
  font-family: Literata, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-torea-bay-16px {
  color: #103da0;
  font-family: Lato, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.lato-bold-mirage-54px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-white-38px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-athens-gray-18px {
  color: #eff0f6;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.lato-medium-mirage-18px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.lato-normal-shadow-blue-16px {
  color: #8083a2;
  font-family: Lato, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.lato-bold-catalina-blue-40px {
  color: #0d2c6f;
  font-family: Lato, Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-white-54px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-shadow-blue-16px {
  color: #8083a2;
  font-family: Lato, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.lato-medium-mirage-20px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.lato-bold-shadow-blue-18px {
  color: #8083a2;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-mirage-20px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.lato-bold-zircon-54px {
  color: #f7fbff;
  font-family: Lato, Helvetica;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-white-16px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.lato-medium-torea-bay-24px {
  color: #103da0;
  font-family: Lato, Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.literata-bold-shadow-blue-16px {
  color: #8083a2;
  font-family: Literata, Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-white-18px {
  color: #fff;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.lato-bold-mirage-46px {
  color: #14132a;
  font-family: Lato, Helvetica;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}

.lato-medium-snuff-18px {
  color: #dbddea;
  font-family: Lato, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}



.group-39358 {
  min-width: 1172px;
  align-items: flex-start;
  margin-left: -1px;
  display: flex;
}

.group-39329 {
  height: 590px;
  width: 373px;
  background-size: 100% 100%;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-39330 {
  height: 590px;
  width: 374px;
  background-size: 100% 100%;
  margin-left: 26px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-39331 {
  height: 590px;
  width: 372px;
  background-size: 100% 100%;
  margin-left: 27px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-39358.team-cards {
  height: 590px;
  margin-left: unset;
  position: absolute;
  top: 330px;
  left: 133px;
}

.team-1 {
  min-height: 1244px;
  width: 1171px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 433px;
  left: 135px;
}

.group-39355 {
  min-width: 1174px;
  align-items: flex-start;
  margin-top: 63px;
  margin-left: -2px;
  display: flex;
}

.group-39332 {
  height: 591px;
  width: 374px;
  background-size: 100% 100%;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-39333 {
  height: 591px;
  width: 376px;
  background-size: 100% 100%;
  align-self: center;
  margin-left: 25px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-39334 {
  height: 591px;
  width: 374px;
  background-size: 100% 100%;
  margin-left: 25px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.primary-btn-2 {
  height: 64px;
  min-width: 206px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 33px;
  padding: 21px 40px;
  display: flex;
}

.button-text-2 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 126px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-2.primary-btn-3 {
  margin-bottom: 11px;
  margin-left: 296px;
  margin-top: unset;
}

.group-75 {
  height: 29px;
  min-width: 29px;
  background-color: #009eff;
  border-radius: 80px;
  align-items: flex-end;
  margin-left: 16px;
  padding: 8.1px 6.7px;
  display: flex;
}

.vector {
  height: 13px;
  width: 15px;
}

.group-75.group-77, .group-75.group-77-1, .group-75.group-77-2, .group-75.group-77-3, .group-75.group-77-4, .group-75.group-77-5, .group-75.group-77-6, .group-75.group-77-7, .group-75.group-77-8 {
  background-color: red;
  align-self: flex-end;
  padding: 8.4px 6.1px;
}

.group-75.group-77 .vector, .group-75.group-77-1 .vector, .group-75.group-77-2 .vector, .group-75.group-77-3 .vector, .group-75.group-77-4 .vector, .group-75.group-77-5 .vector, .group-75.group-77-6 .vector, .group-75.group-77-7 .vector, .group-75.group-77-8 .vector {
  height: 12px;
  width: 17px;
}

.group-73 {
  height: 29px;
  min-width: 29px;
  background: radial-gradient(50% 50%, #fa8f21 9%, #eb2482 78%);
  border-radius: 80px;
  align-self: center;
  align-items: flex-end;
  margin-top: .04px;
  margin-left: 16px;
  padding: 5.9px 5.8px;
  display: flex;
}

.icon-instagram {
  height: 17px;
  width: 17px;
}

.group-73.group-79, .group-73.group-79-1, .group-73.group-79-2, .group-73.group-79-3, .group-73.group-79-4, .group-73.group-79-5, .group-73.group-79-6, .group-73.group-79-7, .group-73.group-79-8 {
  align-items: flex-start;
  align-self: unset;
  background: unset;
  margin-top: unset;
  background-color: #53e447;
  padding: 5.1px 6px;
}

.team {
  height: 3346px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group2 {
  height: 1677px;
  width: 1446px;
  margin-left: 5.98px;
  position: relative;
}

.header {
  min-height: 99px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flex-row {
  min-width: 1173px;
  align-items: center;
  margin-top: 14px;
  margin-right: 1px;
  display: flex;
}

.image-391 {
  height: 59px;
  object-fit: cover;
  width: 110px;
}

.navbar {
  min-width: 627px;
  border: 1px;
  align-items: center;
  margin-bottom: 1px;
  margin-left: 436px;
  display: flex;
}

.frame-36812 {
  height: 40px;
  min-width: 70px;
  border: 1px;
  align-items: flex-start;
  padding: 9px 10px;
  display: flex;
}

.sub-headings {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.navbar-link-sub-headings {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 110px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-1 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 79px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-2 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 36px;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-3 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 36px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.primary-btn {
  height: 46px;
  min-width: 136px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 8px;
  align-items: flex-start;
  margin-left: 32px;
  padding: 13px 24px;
  display: flex;
}

.button-text {
  letter-spacing: 0;
  min-height: 18px;
  min-width: 88px;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}

.line-478 {
  height: 1px;
  width: 1440px;
  margin-top: 17px;
}

.overlap-group1 {
  height: 470px;
  min-width: 1442px;
  background-color: #0d2c6f;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 94px;
  left: 0;
}

.e-container {
  min-height: 470px;
  width: 205px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -.48px;
  display: flex;
}

.rectangle-4108 {
  height: 205px;
  width: 205px;
}

.ellipse-412 {
  height: 264px;
  width: 131px;
  margin-top: 1px;
  margin-left: 0;
}

.flex-col {
  min-height: 132px;
  width: 533px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-bottom: 56.94px;
  margin-left: 248px;
  display: flex;
}

.title {
  letter-spacing: 0;
  min-height: 61px;
  min-width: 128px;
  text-align: center;
  white-space: nowrap;
  margin-left: 0;
  line-height: 61px;
}

.sub-headings-3 {
  letter-spacing: 0;
  min-height: 60px;
  text-align: center;
  width: 533px;
  margin-top: 11px;
  line-height: 30px;
}

.e-container-1 {
  height: 470px;
  width: 264px;
  margin-bottom: -.1px;
  margin-left: 191px;
  position: relative;
}

.ellipse-413 {
  height: 264px;
  width: 263px;
  background-color: #132999;
  border: .77px;
  border-radius: 131.45px / 131.84px;
  position: absolute;
  top: 207px;
  left: 0;
  transform: rotate(90deg);
}

.rectangle-4114 {
  height: 208px;
  width: 208px;
  background-color: #103da0;
  border: .62px;
  position: absolute;
  top: 0;
  left: 56px;
}

.ellipse-419 {
  height: 206px;
  width: 206px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 102.9px;
  position: absolute;
  top: 0;
  left: 56px;
}

.were-looking-to-add {
  letter-spacing: 0;
  min-height: 68px;
  text-align: center;
  width: 404px;
  margin-top: 59px;
  line-height: 34px;
}

.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  min-height: 60px;
  text-align: center;
  width: 491px;
  margin-top: 13px;
  line-height: 30px;
}

.footer {
  min-height: 592px;
  width: 1440px;
  border: 1px;
  flex-direction: column;
  align-items: center;
  margin-top: 182px;
  padding: 1px 0;
  display: flex;
}

.line-443 {
  height: 1px;
  width: 1440px;
  margin-right: .04px;
}

.flex-row-1 {
  height: 335px;
  min-width: 1174px;
  align-items: flex-start;
  margin-top: 79px;
  display: flex;
}

.group-container {
  min-height: 328px;
  width: 369px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  display: flex;
}

.group-39615 {
  height: 231px;
  min-width: 369px;
  align-items: flex-start;
  margin-left: .1px;
  display: flex;
}

.flex-col-1 {
  min-height: 232px;
  width: 186px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -1px;
  display: flex;
}

.sub-headings-4 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 20px;
}

.group-39614 {
  min-height: 172px;
  width: 186px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.sub-headings-1 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  margin-bottom: 0;
  line-height: 20px;
}

.sub-headings-2 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: 24px;
  margin-bottom: 0;
  line-height: 20px;
}

.sub-headings-5 {
  letter-spacing: 0;
  min-height: 40px;
  width: 81px;
  margin-top: 24px;
  line-height: 20px;
}

.group-39613 {
  min-height: 108px;
  width: 147px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 4.8px;
  margin-left: 40px;
  display: flex;
}

.group-2793 {
  min-width: 255px;
  align-items: flex-start;
  margin-top: 68px;
  display: flex;
  position: relative;
}

.group-2787 {
  height: 29px;
  min-width: 29px;
  background-color: #1781fd;
  border-radius: 80px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 6.4px 10px;
  display: flex;
}

.icon-facebook {
  height: 16px;
  width: 9px;
}

.group-78 {
  height: 29px;
  min-width: 29px;
  background-color: #157de6;
  border-radius: 80px;
  align-items: flex-end;
  margin-left: 16px;
  padding: 7.4px 7.1px;
  display: flex;
}

.icon-linkedin {
  height: 14px;
  width: 15px;
}

.group-39616 {
  min-height: 335px;
  width: 457px;
  background-color: #103da0;
  background-size: 100% 100%;
  border-radius: 13px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 348px;
  padding: 54.9px 45px;
  display: flex;
}

.subscribe-to-our-newsletter {
  letter-spacing: 0;
  min-height: 34px;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 34px;
}

.overlap-group {
  height: 62px;
  min-width: 364px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 44px;
  padding: 19px 24px;
  display: flex;
}

.primary-btn-1 {
  height: 64px;
  min-width: 364px;
  background-color: #fff;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 22px;
  margin-left: .34px;
  padding: 21px 143px;
  display: flex;
}

.button-text-1 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 78px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.line-444 {
  height: 1px;
  width: 1174px;
  margin-top: 70px;
  margin-right: .32px;
}

.copyright-marketin {
  letter-spacing: 0;
  min-height: 30px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 32px;
  margin-bottom: 0;
  margin-left: 133px;
  line-height: 30px;
}

.header-1 {
  min-height: 99px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  margin-left: .82px;
  padding: 4px 0;
  display: flex;
}

.flex-row-3 {
  min-width: 1173px;
  align-items: center;
  margin-top: 14px;
  margin-right: 1px;
  display: flex;
}

.image-391-1 {
  height: 59px;
  object-fit: cover;
  width: 110px;
}

.navbar-1 {
  min-width: 627px;
  border: 1px;
  align-items: center;
  margin-bottom: 1px;
  margin-left: 436px;
  display: flex;
}

.frame-36812-1 {
  height: 40px;
  min-width: 70px;
  border: 1px;
  align-items: flex-start;
  padding: 9px 10px;
  display: flex;
}

.sub-headings-9 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.navbar-link-sub-headings-4 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 110px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-5 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 79px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-6 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 36px;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.navbar-link-sub-headings-7 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 36px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 32px;
  line-height: 20px;
}

.primary-btn-6 {
  height: 46px;
  min-width: 136px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 8px;
  align-items: flex-start;
  margin-left: 32px;
  padding: 13px 24px;
  display: flex;
}

.button-text-6 {
  letter-spacing: 0;
  min-height: 18px;
  min-width: 88px;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
}

.line-478-1 {
  height: 1px;
  width: 1440px;
  margin-top: 17px;
}

.header-1.header-2 {
  margin-left: .88px;
}

.header-1.header-3 {
  margin-left: unset;
  position: absolute;
  top: 0;
  left: 155px;
}

.header-1.header-4, .header-1.header-5, .header-1.header-7 {
  margin-left: unset;
  position: absolute;
  top: 0;
  left: 0;
}

.header-1.header-6 {
  margin-left: unset;
  position: absolute;
  top: 68px;
  left: 202px;
}

.header-1.header-8 {
  margin-left: unset;
}

.frame-35995-13 {
  height: 20px;
  min-width: 127px;
  border: 1px;
  align-items: flex-start;
  margin-left: .04px;
  display: flex;
}

.sub-headings-17 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.frame-35995-13.frame-35995-1 {
  margin-left: 46.47px;
}

.frame-35995-13.frame-35995-2 {
  margin-left: 59.97px;
}

.frame-35995-13.frame-35995-3, .frame-35995-13.frame-35995-4 {
  margin-left: unset;
  align-self: center;
  margin-right: 83.06px;
}

.frame-35995-13.frame-35995-5, .frame-35995-13.frame-35995-8, .frame-35995-13.frame-35995-11 {
  height: 20px;
  min-width: 127px;
  border: 1px;
  align-items: flex-start;
  margin-left: 46.47px;
  display: flex;
}

.frame-35995-13.frame-35995-6, .frame-35995-13.frame-35995-9, .frame-35995-13.frame-35995-12 {
  height: 20px;
  min-width: 127px;
  border: 1px;
  align-items: flex-start;
  margin-left: 59.97px;
  display: flex;
}

.frame-35995-13.frame-35995-7, .frame-35995-13.frame-35995-10 {
  height: 20px;
  min-width: 127px;
  border: 1px;
  align-self: center;
  align-items: flex-start;
  margin-right: 83.06px;
  display: flex;
}

.frame-35996-13 {
  height: 20px;
  min-width: 110px;
  border: 1px;
  align-items: flex-start;
  margin-top: 29px;
  margin-left: .04px;
  display: flex;
}

.sub-headings-21 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 92px;
  margin-top: -1px;
  line-height: 20px;
}

.group {
  height: 10px;
  width: 5px;
  align-self: center;
  margin-top: 0;
  margin-left: 13px;
}

.frame-35996-13.frame-35996-1 {
  margin-left: unset;
  margin-top: 22px;
}

.frame-35996-13.frame-35996-2 {
  margin-left: unset;
  margin-top: 20px;
}

.frame-35996-13.frame-35996-4, .frame-35996-13.frame-35996-7, .frame-35996-13.frame-35996-10 {
  height: 20px;
  min-width: 110px;
  border: 1px;
  align-items: flex-start;
  margin-top: 24px;
  display: flex;
}

.frame-35996-13.frame-35996-5, .frame-35996-13.frame-35996-8, .frame-35996-13.frame-35996-11 {
  height: 20px;
  min-width: 110px;
  border: 1px;
  align-items: flex-start;
  margin-top: 22px;
  display: flex;
}

.frame-35996-13.frame-35996-5 .group, .frame-35996-13.frame-35996-11 .group, .frame-35996-13.frame-35996-6 .group, .frame-35996-13.frame-35996-7 .group, .frame-35996-13.frame-35996-12 .group, .frame-35996-13.frame-35996-3-1 .group, .frame-35996-13.frame-35996-3 .group {
  margin-top: unset;
}

.frame-35996-13.frame-35996-6, .frame-35996-13.frame-35996-9, .frame-35996-13.frame-35996-12 {
  height: 20px;
  min-width: 110px;
  border: 1px;
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
}

.frame-35996-13.frame-35996-3, .frame-35996-13.frame-35996-3-1 {
  margin-left: unset;
  margin-top: 24px;
}

.blog-v1-card-1 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 569px;
  left: 133px;
}

.overlap-group1-1 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38094 {
  min-height: 147px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  display: flex;
  position: relative;
}

.address {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 16px;
  line-height: 34px;
}

.blog-v1-card-1.blog-v1-card-1-1 {
  left: unset;
  position: unset;
  top: unset;
  margin-top: 51px;
  margin-left: .03px;
}

.blog-v1-card-1.blog-v1-card-1-2 {
  top: 1289px;
}

.blog-v1-card-1.blog-v1-card-1-3 {
  left: unset;
  position: unset;
  top: unset;
}

.blog-v1-card-4 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 1056px;
  left: 133px;
}

.overlap-group2-1 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38097 {
  min-height: 147px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 26px;
  margin-left: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.address-1 {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 18px;
  line-height: 34px;
}

.blog-v1-card-2 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 569px;
  left: 533px;
}

.overlap-group3 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38095 {
  min-height: 145px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  display: flex;
  position: relative;
}

.what-is-growth-hacki {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 16px;
  line-height: 34px;
}

.blog-v1-card-2.blog-v1-card-2-1 {
  left: unset;
  position: unset;
  top: unset;
  align-self: center;
  margin-top: 46.54px;
  margin-left: 26px;
}

.blog-v1-card-2.blog-v1-card-2-2 {
  top: 1289px;
}

.blog-v1-card-2.blog-v1-card-2-3 {
  left: unset;
  position: unset;
  top: unset;
  margin-left: 26px;
}

.blog-v1-card-5 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 1056px;
  left: 533px;
}

.overlap-group4 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38098 {
  min-height: 147px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 26px;
  margin-left: 0;
  display: flex;
  position: relative;
}

.aso-how-to-rank-you {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 18px;
  margin-left: 0;
  line-height: 34px;
}

.blog-v1-card-3 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 569px;
  left: 933px;
}

.overlap-group5 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38096-1 {
  min-height: 149px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  display: flex;
  position: relative;
}

.address-2 {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 16px;
  line-height: 34px;
}

.blog-v1-card-3.blog-v1-card-3-1 {
  left: unset;
  position: unset;
  top: unset;
  margin-top: 43px;
}

.blog-v1-card-3.blog-v1-card-3-2 {
  top: 1289px;
}

.blog-v1-card-3.blog-v1-card-3-3 {
  top: 0;
  left: 0;
}

.blog-v1-card-3.blog-v1-card-4-1 {
  left: unset;
  position: unset;
  top: unset;
}

.blog-v1-card-6 {
  min-height: 425px;
  width: 374px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 1056px;
  left: 933px;
}

.overlap-group6 {
  height: 250px;
  width: 374px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border-radius: 13.83px;
}

.group-38099 {
  min-height: 147px;
  width: 376px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  display: flex;
  position: relative;
}

.address-3 {
  letter-spacing: 0;
  min-height: 68px;
  width: 374px;
  margin-top: 16px;
  line-height: 34px;
}

.cta {
  height: 297px;
  min-width: 1174px;
  background-color: #103da0;
  border: 1px;
  border-radius: 24px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 133px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.overlap-group7 {
  height: 297px;
  width: 1174px;
  margin-bottom: -.04px;
  position: relative;
}

.rectangle-4032 {
  height: 148px;
  width: 148px;
  position: absolute;
  top: 149px;
  left: 1026px;
}

.mask-group-1 {
  height: 297px;
  width: 1174px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-38107 {
  height: 100px;
  min-width: 1028px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 99px;
  left: 74px;
}

.subscribe-to-our-newsletter-1 {
  letter-spacing: 0;
  min-height: 100px;
  width: 338px;
  margin-top: -1px;
  line-height: 50px;
}

.overlap-group-4 {
  height: 80px;
  min-width: 555px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  justify-content: flex-end;
  align-self: center;
  align-items: flex-start;
  margin-bottom: .72px;
  margin-left: 133px;
  padding: 7px 10px;
  display: flex;
  box-shadow: 0 3px 12px #0000000d;
}

.sub-headings-25 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 197px;
  white-space: nowrap;
  align-self: center;
  margin-bottom: 4px;
  line-height: 20px;
}

.primary-btn-14 {
  height: 64px;
  min-width: 163px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-left: 156px;
  padding: 21px 40px;
  display: flex;
}

.button-text-14 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 83px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.footer-1 {
  min-height: 592px;
  width: 1440px;
  border: 1px;
  flex-direction: column;
  align-items: center;
  padding: 1px 0;
  display: flex;
  position: absolute;
  top: 1790px;
  left: 0;
}

.line-443-1 {
  height: 1px;
  width: 1440px;
  margin-right: .04px;
}

.flex-row-11 {
  height: 335px;
  min-width: 1174px;
  align-items: flex-start;
  margin-top: 79px;
  display: flex;
}

.group-container-1 {
  min-height: 328px;
  width: 369px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  display: flex;
}

.group-39615-1 {
  height: 231px;
  min-width: 369px;
  align-items: flex-start;
  margin-left: .1px;
  display: flex;
}

.flex-col-2 {
  min-height: 232px;
  width: 186px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -1px;
  display: flex;
}

.sub-headings-42 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 20px;
}

.group-39614-1 {
  min-height: 172px;
  width: 186px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.sub-headings-26 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  margin-bottom: 0;
  line-height: 20px;
}

.sub-headings-27 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: 24px;
  margin-bottom: 0;
  line-height: 20px;
}

.sub-headings-43 {
  letter-spacing: 0;
  min-height: 40px;
  width: 81px;
  margin-top: 24px;
  line-height: 20px;
}

.group-39613-1 {
  min-height: 108px;
  width: 147px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 4.8px;
  margin-left: 40px;
  display: flex;
}

.group-2793-1 {
  min-width: 255px;
  align-items: flex-start;
  margin-top: 68px;
  display: flex;
  position: relative;
}

.group-2787-1 {
  height: 29px;
  min-width: 29px;
  background-color: #1781fd;
  border-radius: 80px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 6.4px 10px;
  display: flex;
}

.icon-facebook-1 {
  height: 16px;
  width: 9px;
}

.group-78-1 {
  height: 29px;
  min-width: 29px;
  background-color: #157de6;
  border-radius: 80px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 16px;
  padding: 7.4px 7.1px;
  display: flex;
}

.icon-linkedin-1 {
  height: 14px;
  width: 15px;
}

.group-39616-1 {
  min-height: 335px;
  width: 457px;
  background-color: #103da0;
  background-size: 100% 100%;
  border-radius: 13px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 348px;
  padding: 54.9px 45px;
  display: flex;
}

.subscribe-to-our-newsletter-2 {
  letter-spacing: 0;
  min-height: 34px;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 34px;
}

.overlap-group-5 {
  height: 62px;
  min-width: 364px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 44px;
  padding: 19px 24px;
  display: flex;
}

.sub-headings-44 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-15 {
  height: 64px;
  min-width: 364px;
  background-color: #fff;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 22px;
  margin-left: .34px;
  padding: 21px 143px;
  display: flex;
}

.button-text-15 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 78px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.line-444-1 {
  height: 1px;
  width: 1174px;
  margin-top: 70px;
  margin-right: .32px;
}

.copyright-marketin-1 {
  letter-spacing: 0;
  min-height: 30px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 32px;
  margin-bottom: 0;
  margin-left: 133px;
  line-height: 30px;
}

.footer-1.footer-2, .footer-1.footer-3, .footer-1.footer-5, .footer-1.footer-6, .footer-1.footer-7, .footer-1.footer-8 {
  left: unset;
  position: unset;
  top: unset;
}

.footer-1.footer-3 .group-78-1, .footer-1.footer-4 .group-78-1, .footer-1.footer-5 .group-78-1, .footer-1.footer-6 .group-78-1, .footer-1.footer-8 .group-78-1 {
  justify-content: unset;
}

.footer-1.footer-4 {
  top: 1658px;
}

.polis-blog {
  height: 3797px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flex-row-2 {
  height: 132px;
  min-width: 1161px;
  align-items: center;
  margin-top: 108px;
  margin-right: 13.38px;
  display: flex;
}

.blog-title-goes-here {
  letter-spacing: 0;
  min-height: 132px;
  width: 649px;
  line-height: 61px;
}

.sub-headings-6 {
  letter-spacing: 0;
  min-height: 89px;
  width: 457px;
  margin-top: 0;
  margin-left: 55px;
  line-height: 30px;
}

.overlap-group8 {
  height: 433px;
  width: 1174px;
  margin-top: 36px;
  margin-right: .05px;
  position: relative;
}

.rectangle-4047 {
  height: 433px;
  width: 649px;
  background-color: #c4c4c4;
  border: 1px;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.featured-post-1 {
  height: 433px;
  min-width: 1174px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.mask-group {
  height: 433px;
  width: 649px;
  margin-top: 0;
}

.group-38093 {
  min-height: 296px;
  width: 474px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-bottom: .22px;
  margin-left: 55px;
  display: flex;
  position: relative;
}

.overlap-group-1 {
  height: 211px;
  width: 470px;
  margin-top: 16px;
  position: relative;
}

.what-is-personal-education {
  letter-spacing: 0;
  width: 470px;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.sub-headings-7 {
  letter-spacing: 0;
  width: 457px;
  line-height: 30px;
  position: absolute;
  top: 122px;
  left: 0;
}

.featured-post-1-1 {
  min-width: 1174px;
  align-items: flex-start;
  margin-top: 56px;
  margin-left: .41px;
  display: flex;
}

.group-38093-1 {
  min-height: 247px;
  width: 474px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-top: 1.3px;
  margin-left: 55px;
  display: flex;
  position: relative;
}

.why-we-founded-polis {
  letter-spacing: 0;
  min-height: 72px;
  width: 470px;
  margin-top: 17px;
  line-height: 50px;
}

.sub-headings-8 {
  letter-spacing: 0;
  min-height: 89px;
  width: 457px;
  line-height: 30px;
}

.overlap-group9 {
  height: 2382px;
  width: 1440px;
  margin-top: 118px;
  margin-left: .02px;
  position: relative;
}

.rectangle-4054 {
  height: 1620px;
  width: 1440px;
  background-color: #f7fbff;
  border: 1px;
  position: absolute;
  top: 170px;
  left: 0;
}

.latest-posts {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 50px;
  position: absolute;
  top: 458px;
  left: 133px;
}

.group-38112 {
  height: 64px;
  min-width: 294px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 1566px;
  left: 574px;
}

.primary-btn-4 {
  height: 64px;
  min-width: 150px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 10px;
  align-items: flex-start;
  padding: 21px 40px;
  display: flex;
  box-shadow: 0 4px 4px #0000000d;
}

.button-text-4 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 70px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-5 {
  height: 64px;
  min-width: 120px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-left: 24px;
  padding: 21px 40px;
  display: flex;
}

.button-text-5 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 40px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.secondary-btn-1 {
  height: 64px;
  min-width: 209px;
  border: 1px solid #103da0;
  border-radius: 10px;
  align-items: flex-start;
  margin-right: 11px;
  padding: 21px 40px;
  display: flex;
}

.button-text-24 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 129px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.secondary-btn-1.secondary-btn-2 {
  margin-left: 24px;
  margin-right: unset;
  min-width: 208px;
}

.secondary-btn-1.secondary-btn-2 .button-text-24 {
  min-width: 128px;
}

.blog-post {
  height: 7990px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame-35996-14 {
  height: 20px;
  min-width: 157px;
  border: 1px;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: flex-start;
  margin-top: 23px;
  margin-left: 133.44px;
  padding: 0;
  display: flex;
}

.group-1 {
  height: 10px;
  width: 5px;
  align-self: center;
  margin-top: 0;
}

.sub-headings-66 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  margin-left: 13px;
  line-height: 20px;
}

.secondary-btn {
  height: 53px;
  min-width: 158px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 52px;
  margin-right: 615.83px;
  padding: 15px 24px;
  display: flex;
}

.icon-search {
  height: 19px;
  width: 19px;
  align-self: center;
  margin-bottom: 1px;
}

.button-text-23 {
  letter-spacing: .36px;
  min-height: 20px;
  min-width: 83px;
  text-align: center;
  white-space: nowrap;
  margin-left: 8px;
  line-height: 20px;
}

.what-is-personal-education-1 {
  letter-spacing: 0;
  min-height: 61px;
  white-space: nowrap;
  width: 772px;
  margin-top: 33px;
  line-height: 61px;
}

.we-need-to-figure-ou {
  letter-spacing: 0;
  min-height: 60px;
  width: 772px;
  margin-top: 33px;
  margin-right: .49px;
  line-height: 30px;
}

.flex-row-19 {
  min-width: 196px;
  align-items: center;
  margin-top: 19px;
  margin-right: 575.67px;
  display: flex;
}

.mask-group-2 {
  height: 55px;
  width: 55px;
}

.frame-35996-15 {
  height: 20px;
  min-width: 127px;
  border: 1px;
  align-items: flex-start;
  margin-bottom: 1.33px;
  margin-left: 14px;
  display: flex;
}

.sub-headings-67 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.blog-image-7 {
  height: 713px;
  width: 1173px;
  background-color: #c4c4c4;
  background-size: 100% 100%;
  border: 2.11px;
  border-radius: 56.3px;
  margin-top: 95px;
  margin-right: .13px;
}

.group-7127 {
  height: 1404px;
  min-width: 774px;
  align-items: flex-start;
  margin-top: 89px;
  margin-left: .03px;
  display: flex;
}

.rich-text {
  min-height: 1404px;
  width: 774px;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.our-goal-is-to-provi {
  letter-spacing: 0;
  min-height: 250px;
  width: 772px;
  margin-top: -1px;
  line-height: 50px;
}

.overlap-group6-1 {
  height: 135px;
  width: 772px;
  margin-top: 1px;
  position: relative;
}

.margin-1 {
  height: 16px;
  width: 24px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.though-the-phrase-p, .in-recent-years-tho, .though-well-meaning, .personalized-learnin {
  letter-spacing: 0;
  width: 772px;
  line-height: 30px;
  position: absolute;
  top: 15px;
  left: 0;
}

.margin-2 {
  height: 32px;
  width: 32px;
  border: 1px solid #fff;
  margin-top: 1px;
}

.overlap-group7-1 {
  height: 73px;
  width: 772px;
  position: relative;
}

.margin-3 {
  height: 40px;
  width: 48px;
  border: 1px solid #f9f9f9;
  position: absolute;
  top: 0;
  left: 0;
}

.more-than-just-buzzwords {
  letter-spacing: 0;
  white-space: nowrap;
  width: 772px;
  line-height: 34px;
  position: absolute;
  top: 39px;
  left: 0;
}

.overlap-group5-1 {
  height: 315px;
  width: 772px;
  margin-top: 1px;
  position: relative;
}

.margin {
  height: 16px;
  width: 16px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group-14 {
  height: 300px;
  min-width: 774px;
  background-color: #fff;
  background-size: 100% 100%;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  align-items: flex-start;
  margin-left: -.97px;
  padding: 57.8px 86.8px;
  display: flex;
}

.instruction-in-whic {
  letter-spacing: 0;
  min-height: 174px;
  width: 600px;
  line-height: 32px;
}

.overlap-group2-3 {
  height: 298px;
  width: 772px;
  margin-top: 1px;
  position: relative;
}

.margin-4 {
  height: 29px;
  width: 56px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.such-a-broad-definit {
  letter-spacing: 0;
  width: 772px;
  line-height: 30px;
  position: absolute;
  top: 28px;
  left: 0;
}

.margin24px {
  height: 24px;
  width: 24px;
  border: 1px solid #fff;
  margin-top: 1px;
}

.overlap-group9-1 {
  height: 383px;
  width: 772px;
  position: relative;
}

.image {
  height: 375px;
  width: 772px;
  position: absolute;
  top: 0;
  left: 0;
}

.margin-5 {
  height: 8px;
  width: 24px;
  border: 1px solid #fff;
  position: absolute;
  top: 375px;
  left: 0;
}

.overlap-group-13 {
  height: 89px;
  width: 772px;
  position: relative;
}

.margin48px {
  height: 56px;
  width: 56px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.a-messy-reality, .personalized-education {
  letter-spacing: 0;
  white-space: nowrap;
  width: 772px;
  line-height: 34px;
  position: absolute;
  top: 55px;
  left: 0;
}

.overlap-group4-1 {
  height: 1305px;
  width: 772px;
  margin-top: 1px;
  position: relative;
}

.image-1 {
  height: 375px;
  width: 772px;
  margin-top: 1px;
}

.overlap-group1-4 {
  height: 883px;
  width: 772px;
  margin-top: 1px;
  position: relative;
}

.span2 {
  color: #ff6262;
  font-family: Lato, Helvetica;
  font-size: 24px;
  font-weight: 700;
}

.group-39720 {
  min-height: 415px;
  width: 774px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3240px;
  display: flex;
}

.line-477 {
  height: 1px;
  width: 774px;
}

.group-container-9 {
  height: 338px;
  min-width: 774px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 24px;
  align-items: flex-start;
  margin-top: 76px;
  padding: 57px 46.5px;
  display: flex;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.mask-group-3 {
  height: 222px;
  width: 222px;
}

.group-39499 {
  min-height: 171px;
  width: 423px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-top: 4.22px;
  margin-left: 34px;
  display: flex;
}

.group-39629 {
  min-height: 48px;
  width: 146px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.name {
  letter-spacing: 0;
  min-height: 22px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 22px;
}

.sub-headings-68 {
  letter-spacing: 0;
  min-height: 18px;
  white-space: nowrap;
  margin-top: 8px;
  line-height: 18px;
}

.lorem-ipsum-dolor-si-1 {
  letter-spacing: 0;
  min-height: 60px;
  width: 423px;
  margin-top: 8px;
  line-height: 30px;
}

.group-2793-9 {
  min-width: 134px;
  align-items: flex-start;
  margin-top: 29px;
  display: flex;
}

.group-2787-9 {
  height: 25px;
  min-width: 25px;
  background-color: #1781fd;
  border-radius: 122.92px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 0;
  padding: 5.5px 8.7px;
  display: flex;
  box-shadow: 0 4.01px 12.96px #1574ef36;
}

.icon-facebook-9 {
  height: 14px;
  width: 8px;
}

.group-75-2 {
  height: 25px;
  min-width: 25px;
  background-color: #009eff;
  border-radius: 122.92px;
  align-items: flex-end;
  margin-left: 11px;
  padding: 7px 5.8px;
  display: flex;
  box-shadow: 0 4.01px 12.96px #1abaff3d;
}

.vector-18 {
  height: 11px;
  width: 13px;
}

.group-73-1 {
  height: 25px;
  min-width: 25px;
  background: radial-gradient(50% 50%, #fa8f21 9%, #eb2482 78%);
  border-radius: 122.92px;
  align-self: flex-end;
  align-items: flex-end;
  margin-left: 11px;
  padding: 5.1px 5px;
  display: flex;
  box-shadow: 0 4.91px 12.96px #fa0e5a24;
}

.vector-19 {
  height: 15px;
  width: 15px;
}

.group-78-3 {
  height: 25px;
  min-width: 25px;
  background-color: #157de6;
  border-radius: 122.92px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 11px;
  padding: 0 6.1px;
  display: flex;
  box-shadow: 0 4.01px 12.96px #147ee73b;
}

.icon-linkedin-9 {
  height: 12px;
  width: 13px;
}

.overlap-group14 {
  height: 836px;
  min-width: 1451px;
  background-color: #f7fbff;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 97px;
  margin-left: 1px;
  padding: 122px 136px;
  display: flex;
  position: relative;
}

.flex-col-10 {
  min-height: 526px;
  width: 374px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6px;
  display: flex;
  position: relative;
}

.latest-articles {
  letter-spacing: 0;
  min-height: 50px;
  white-space: nowrap;
  line-height: 50px;
}

.flex-col-11 {
  min-height: 532px;
  width: 374px;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 26px;
  display: flex;
  position: relative;
}

.group-39122 {
  height: 143px;
  min-width: 636px;
  align-items: flex-start;
  margin-top: 137px;
  margin-left: 9px;
  display: flex;
}

.group-2068 {
  min-height: 137px;
  width: 591px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  display: flex;
}

.heading-h2-2 {
  letter-spacing: 0;
  min-height: 28px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 28px;
}

.paragraph {
  letter-spacing: -.16px;
  min-height: 96px;
  width: 587px;
  margin-top: 13px;
  line-height: 32px;
}

.group-39115 {
  height: 40px;
  width: 40px;
  margin-left: 5px;
}

.group-39122.group-39122-1 {
  margin-top: 94px;
}

.group-39122.group-39122-2 {
  margin-top: 117px;
}

.polis-faq {
  height: 5024px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group5-2 {
  height: 1350px;
  width: 1607px;
  margin-left: -154.95px;
  position: relative;
}

.overlap-group3-2 {
  height: 640px;
  width: 1595px;
  position: absolute;
  top: 94px;
  left: 0;
}

.have-questions-we-have-answers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 50px;
  position: absolute;
  top: 224px;
  left: 580px;
}

.sub-headings-69 {
  letter-spacing: 0;
  text-align: center;
  width: 530px;
  line-height: 30px;
  position: absolute;
  top: 284px;
  left: 610px;
}

.frequently-asked-questions {
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  position: absolute;
  top: 192px;
  left: 730px;
}

.rectangle-4114-1 {
  height: 205px;
  width: 205px;
  position: absolute;
  top: 345px;
  left: 420px;
}

.rectangle-4364 {
  height: 640px;
  width: 1440px;
  background-color: #132999;
  border: 1px;
  position: absolute;
  top: 0;
  left: 155px;
}

.group-39242 {
  height: 309px;
  min-width: 310px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 .5px;
  display: flex;
  position: absolute;
  top: 208px;
  left: 0;
}

.ellipse-413-1 {
  height: 310px;
  width: 309px;
  background-color: #ffd600;
  border: .6px;
  border-radius: 154.49px / 154.95px;
  margin-top: -.46px;
  transform: rotate(90deg);
}

.ellipse-420 {
  height: 206px;
  width: 206px;
  background-color: #ff6262;
  border: 1px;
  border-radius: 102.9px;
  position: absolute;
  top: 312px;
  left: 1389px;
}

.have-questions-we-have-answers-1 {
  letter-spacing: 0;
  text-align: center;
  width: 499px;
  line-height: 61px;
  position: absolute;
  top: 155px;
  left: 626px;
}

.sub-headings-70 {
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  position: absolute;
  top: 125px;
  left: 806px;
}

.nav-link-item {
  letter-spacing: 0;
  text-align: center;
  width: 481px;
  line-height: 30px;
  position: absolute;
  top: 291px;
  left: 635px;
}

.rectangle-4379 {
  height: 208px;
  width: 208px;
  background-color: #103da0;
  border: .62px;
  position: absolute;
  top: 0;
  left: 155px;
}

.rectangle-4376 {
  height: 208px;
  width: 208px;
  background-color: #0d2c6f;
  border: .62px;
  position: absolute;
  top: 0;
  left: 1387px;
}

.rectangle-4377 {
  height: 208px;
  width: 208px;
  position: absolute;
  top: 0;
  left: 1387px;
}

.group-39241 {
  height: 208px;
  min-width: 208px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 .3px;
  display: flex;
  position: absolute;
  top: 104px;
  left: 1387px;
  transform: rotate(90deg);
}

.ellipse-413-2 {
  height: 208px;
  width: 208px;
  background-color: #ffd600;
  border: .6px;
  border-radius: 103.85px / 104.16px;
  margin-top: -.31px;
}

.rectangle-4378 {
  height: 208px;
  width: 208px;
  position: absolute;
  top: 0;
  left: 145px;
}

.overlap-group4-2 {
  height: 808px;
  width: 774px;
  border-radius: 18px;
  position: absolute;
  top: 542px;
  left: 488px;
}

.group-39254 {
  min-height: 808px;
  width: 774px;
  background-color: #fff;
  border: .5px solid #e7e8f1;
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  padding: 67.9px 60px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 18px #1e24590d;
}

.line-458 {
  height: 1px;
  width: 627px;
  margin-top: 34px;
  margin-right: 2px;
}

.flex-row-20 {
  min-width: 640px;
  align-self: flex-end;
  align-items: center;
  margin-top: 48px;
  margin-right: 0;
  display: flex;
}

.heading-h2 {
  letter-spacing: 0;
  min-height: 28px;
  white-space: nowrap;
  width: 526px;
  margin-bottom: 2px;
  line-height: 28px;
}

.group-39 {
  height: 40px;
  width: 40px;
  margin-left: 74px;
}

.line-459 {
  height: 1px;
  width: 627px;
  margin-top: 45px;
  margin-right: 2px;
}

.flex-row-21 {
  min-width: 640px;
  align-self: flex-end;
  align-items: center;
  margin-top: 50px;
  margin-right: 0;
  display: flex;
}

.line-46 {
  height: 1px;
  width: 627px;
  margin-top: 43px;
  margin-right: 2px;
}

.flex-row-22 {
  min-width: 640px;
  align-self: flex-end;
  align-items: center;
  margin-top: 48px;
  display: flex;
}

.general-questions {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 499px;
  line-height: 61px;
  position: absolute;
  top: 68px;
  left: 138px;
}

.overlap-group-container {
  height: 2909px;
  width: 1442px;
  margin-top: 173px;
  position: relative;
}

.overlap-group1-5 {
  height: 1455px;
  width: 1440px;
  background-size: 100% 100%;
  position: absolute;
  top: 1454px;
  left: 0;
}

.group-39254-1 {
  min-height: 1093px;
  width: 774px;
  background-color: #fff;
  border: .5px solid #e7e8f1;
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  padding: 105.4px 60px;
  display: flex;
  position: absolute;
  top: 183px;
  left: 333px;
  box-shadow: 0 5px 18px #1e24590d;
}

.flex-row-23 {
  min-width: 640px;
  align-self: flex-end;
  align-items: center;
  margin-top: 42px;
  margin-right: 0;
  display: flex;
}

.heading-h2-1 {
  letter-spacing: 0;
  min-height: 28px;
  white-space: nowrap;
  width: 500px;
  margin-bottom: 2px;
  line-height: 28px;
}

.group-3924 {
  height: 40px;
  width: 40px;
  margin-left: 100px;
}

.flex-row-24 {
  min-width: 640px;
  align-self: flex-end;
  align-items: center;
  margin-top: 43px;
  display: flex;
}

.for-educators {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 499px;
  line-height: 61px;
  position: absolute;
  top: 261px;
  left: 471px;
}

.overlap-group2-4 {
  height: 1455px;
  width: 1440px;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.group-39256 {
  min-height: 1116px;
  width: 774px;
  background-color: #fff;
  border: .5px solid #e7e8f1;
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  padding: 105.4px 60px;
  display: flex;
  position: absolute;
  top: 160px;
  left: 333px;
  box-shadow: 0 5px 18px #1e24590d;
}

.for-learners {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 499px;
  line-height: 61px;
  position: absolute;
  top: 243px;
  left: 471px;
}

.primary-btn-27 {
  height: 64px;
  min-width: 219px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 58px;
  padding: 21px 40px;
  display: flex;
}

.button-text-29 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 139px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-27.primary-btn-28 {
  margin-top: unset;
  background-color: #ff6262;
  position: absolute;
  top: 751px;
  left: 609px;
}

.group-37853 {
  height: 64px;
  min-width: 64px;
  background-color: #103da0;
  border-radius: 32px;
  align-items: flex-end;
  padding: 21.6px 21.1px;
  display: flex;
  position: absolute;
  top: 488px;
  left: 1271px;
}

.group-7 {
  height: 21px;
  width: 22px;
}

.group-37853.group-37853-1 {
  background-color: #ff6262;
  justify-content: flex-end;
  top: 464px;
  left: 1274px;
  box-shadow: 0 6px 16px #4939ff30;
}

.group-37853.group-39132 {
  justify-content: flex-end;
  top: 93px;
  left: 336px;
}

.polis-how-it-works {
  height: 5066px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.overlap-group9-2 {
  height: 969px;
  width: 1440px;
  position: relative;
}

.hero-content {
  height: 875px;
  min-width: 1307px;
  align-items: flex-start;
  padding: 0;
  display: flex;
  position: absolute;
  top: 94px;
  left: 132px;
}

.group-37862 {
  min-height: 626px;
  width: 530px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-top: 82.52px;
  display: flex;
  position: relative;
}

.sub-headings-72 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  margin-top: -1px;
  margin-left: .22px;
  line-height: 18px;
}

.sub-headings-73 {
  letter-spacing: 0;
  min-height: 183px;
  width: 523px;
  margin-top: 30px;
  margin-left: .6px;
  line-height: 61px;
}

.sub-headings-74 {
  letter-spacing: 0;
  min-height: 90px;
  width: 523px;
  margin-top: 34px;
  margin-left: .6px;
  line-height: 30px;
}

.group-37859 {
  height: 104px;
  min-width: 234px;
  align-items: center;
  margin-top: 46px;
  margin-left: .22px;
  display: flex;
}

.group-container-10 {
  min-height: 104px;
  width: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.group-3 {
  height: 24px;
  width: 24px;
}

.group-4 {
  height: 24px;
  width: 24px;
  margin-top: 15px;
}

.group-5 {
  height: 24px;
  width: 24px;
  margin-top: 17px;
}

.sub-headings-container {
  min-height: 100px;
  width: 191px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 13px;
  display: flex;
}

.sub-headings-75 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.sub-headings-76 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: 19px;
  line-height: 20px;
}

.sub-headings-77 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: 21px;
  line-height: 20px;
}

.overlap-group8-1 {
  height: 875px;
  width: 733px;
  margin-left: 45px;
  position: relative;
}

.rectangle-1534 {
  height: 259px;
  width: 286px;
  background-color: #103da0;
  border: 1.45px;
  position: absolute;
  top: 84px;
  left: 0;
}

.rectangle-1513 {
  height: 258px;
  width: 258px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 513px;
  left: 475px;
}

.rectangle-1512 {
  height: 513px;
  width: 447px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 0;
  left: 286px;
}

.ellipse-119 {
  height: 261px;
  width: 261px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 130.63px;
  position: absolute;
  top: 382px;
  left: 420px;
}

.rectangle-1514 {
  height: 269px;
  width: 269px;
  position: absolute;
  top: 0;
  left: 286px;
}

.mask-group-4 {
  height: 709px;
  width: 504px;
  position: absolute;
  top: 166px;
  left: 89px;
}

.nav-indicator {
  height: 1px;
  width: 116px;
  position: absolute;
  top: 18px;
  left: 807px;
}

.using-polis {
  min-height: 1639px;
  width: 1288px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 124px;
  margin-left: 4.35px;
  padding: 0;
  display: flex;
}

.sub-headings-78 {
  letter-spacing: 1.6px;
  min-height: 18px;
  text-align: center;
  white-space: nowrap;
  width: 167px;
  margin-top: -1px;
  margin-right: 3.85px;
  line-height: 18px;
}

.sub-headings-79 {
  letter-spacing: 0;
  min-height: 164px;
  text-align: center;
  width: 681px;
  margin-top: 18px;
  margin-right: 3.35px;
  line-height: 50px;
}

.group-container-11 {
  min-width: 1219px;
  align-self: flex-start;
  align-items: center;
  margin-top: 29px;
  display: flex;
}

.overlap-group7-2 {
  height: 630px;
  width: 633px;
  position: relative;
}

.group-38126 {
  height: 153px;
  width: 153px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-4425 {
  height: 582px;
  width: 587px;
  background-color: #c4c4c4;
  border: 1px;
  border-radius: 24px;
  position: absolute;
  top: 48px;
  left: 46px;
}

.mask-group-5 {
  height: 582px;
  width: 587px;
  position: absolute;
  top: 48px;
  left: 46px;
}

.group-39364 {
  min-height: 322px;
  width: 518px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 48.8px;
  margin-left: 69px;
  display: flex;
}

.sub-headings-container-1 {
  height: 259px;
  width: 474px;
  align-self: flex-end;
  margin-top: -1px;
  position: relative;
}

.sub-headings-80 {
  letter-spacing: 0;
  width: 474px;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.sub-headings-81 {
  letter-spacing: 0;
  width: 474px;
  line-height: 30px;
  position: absolute;
  top: 79px;
  left: 0;
}

.primary-btn-23 {
  height: 64px;
  min-width: 269px;
  border: 3px solid #103da0;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 1px;
  padding: 19px 40px;
  display: flex;
}

.button-text-25 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 189px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.overlap-group-container-1 {
  min-width: 1253px;
  align-self: flex-end;
  align-items: center;
  margin-top: 121px;
  margin-right: 4px;
  display: flex;
}

.overlap-group1-6 {
  height: 336px;
  width: 547px;
  margin-bottom: 46.05px;
  position: relative;
}

.primary-btn-24 {
  height: 64px;
  min-width: 212px;
  border: 3px solid #103da0;
  border-radius: 10px;
  align-items: flex-start;
  padding: 19px 40px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 0;
}

.button-text-26 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 132px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.group-38016 {
  min-height: 284px;
  width: 531px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 17px;
}

.sub-headings-82 {
  letter-spacing: 0;
  min-height: 50px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 50px;
}

.sub-headings-83 {
  letter-spacing: 0;
  min-height: 180px;
  width: 518px;
  margin-top: 54px;
  margin-left: .41px;
  line-height: 30px;
}

.overlap-group6-2 {
  height: 661px;
  width: 652px;
  margin-left: 54px;
  position: relative;
}

.ellipse-110 {
  height: 249px;
  opacity: .08;
  width: 249px;
  background-color: #0061ff;
  border: 1px;
  border-radius: 124.69px;
  position: absolute;
  top: 412px;
  left: 403px;
}

.mask-group-6 {
  height: 582px;
  width: 587px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group10 {
  height: 2250px;
  width: 1711px;
  margin-top: 84px;
  position: relative;
}

.overlap-group4-3 {
  height: 986px;
  width: 1709px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-4051 {
  height: 986px;
  width: 1440px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group-15 {
  min-height: 406px;
  width: 374px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 63px 26.3px;
  display: flex;
  position: absolute;
  top: 317px;
  left: 132px;
}

.group-38028 {
  height: 86px;
  width: 86px;
  background-color: #ff62621a;
  border-radius: 15.46px;
  margin-top: 1px;
}

.sub-headings-84 {
  letter-spacing: 0;
  min-height: 34px;
  text-align: center;
  white-space: nowrap;
  width: 233px;
  margin-top: 23px;
  line-height: 34px;
}

.sub-headings-71 {
  letter-spacing: 0;
  min-height: 90px;
  text-align: center;
  width: 321px;
  align-self: flex-start;
  margin-top: 10px;
  line-height: 30px;
}

.line-451 {
  height: 1px;
  width: 109px;
  margin-top: 35px;
}

.overlap-group1-7 {
  min-height: 406px;
  width: 374px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 63px 26.3px;
  display: flex;
  position: absolute;
  top: 317px;
  left: 533px;
}

.group-38028-1 {
  height: 86px;
  width: 86px;
  background-color: #103da01a;
  border-radius: 15.46px;
  margin-top: 1px;
}

.sub-headings-85 {
  letter-spacing: 0;
  min-height: 34px;
  text-align: center;
  white-space: nowrap;
  width: 216px;
  margin-top: 23px;
  margin-right: 1px;
  line-height: 34px;
}

.overlap-group2-5 {
  min-height: 406px;
  width: 374px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 63px 26.3px;
  display: flex;
  position: absolute;
  top: 317px;
  left: 934px;
}

.group-38028-2 {
  height: 86px;
  width: 86px;
  background-color: #ffd6001a;
  border-radius: 15.46px;
  margin-top: 1px;
}

.sub-headings-86 {
  letter-spacing: 0;
  min-height: 34px;
  text-align: center;
  white-space: nowrap;
  width: 206px;
  margin-top: 23px;
  margin-right: 1px;
  line-height: 34px;
}

.overlap-group3-3 {
  min-height: 406px;
  width: 374px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 63px 26.3px;
  display: flex;
  position: absolute;
  top: 317px;
  left: 1335px;
}

.team-work {
  letter-spacing: 0;
  min-height: 34px;
  text-align: center;
  white-space: nowrap;
  width: 105px;
  margin-top: 23px;
  line-height: 34px;
}

.sub-headings-87 {
  letter-spacing: 0;
  text-align: center;
  width: 527px;
  line-height: 50px;
  position: absolute;
  top: 121px;
  left: 457px;
}

.overlap-group5-3 {
  height: 1025px;
  width: 1614px;
  position: absolute;
  top: 902px;
  left: 0;
}

.rectangle-3859 {
  height: 671px;
  width: 1440px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 84px;
  left: 0;
}

.ellipse-118 {
  height: 542px;
  width: 542px;
  background-color: #ff6262;
  border: .98px;
  border-radius: 271.06px;
  position: absolute;
  top: 483px;
  left: 1072px;
  transform: rotate(90deg);
}

.rectangle-3895 {
  height: 672px;
  width: 508px;
  background-color: #ffd600;
  border: 1px;
  position: absolute;
  top: 84px;
  left: 0;
}

.e-book-2 {
  height: 643px;
  width: 423px;
  background-size: 100% 100%;
  border: .91px;
  position: absolute;
  top: 0;
  left: 195px;
  box-shadow: 0 12.92px 31.37px #14132a0d, 0 5.54px 22.14px #4939ff08;
}

.group-39599 {
  min-height: 516px;
  width: 547px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 127px;
  left: 707px;
}

.sub-headings-88 {
  letter-spacing: 0;
  min-height: 150px;
  width: 543px;
  margin-top: -1px;
  line-height: 50px;
}

.sub-headings-89 {
  letter-spacing: 0;
  min-height: 120px;
  width: 527px;
  margin-top: 42px;
  line-height: 30px;
}

.primary-btn-25 {
  height: 64px;
  min-width: 204px;
  background-color: #fff;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 49px;
  padding: 21px 40px;
  display: flex;
}

.button-text-27 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 124px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-26 {
  height: 64px;
  min-width: 204px;
  border: 3px solid #fff;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 28px;
  padding: 19px 47.5px;
  display: flex;
}

.button-text-28 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 109px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.polis-team-member {
  height: 2465px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group4-4 {
  height: 1873px;
  width: 1440px;
  margin-left: -.02px;
  position: relative;
}

.rectangle-4452 {
  height: 922px;
  width: 469px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 94px;
  left: 0;
}

.ellipse-114 {
  height: 390px;
  width: 390px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 194.83px;
  position: absolute;
  top: 821px;
  left: 0;
}

.rectangle-3862 {
  height: 277px;
  width: 277px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 94px;
  left: 0;
}

.rectangle-4108-1 {
  height: 217px;
  width: 217px;
  position: absolute;
  top: 94px;
  left: 469px;
}

.rectangle-4051-1 {
  height: 857px;
  width: 1440px;
  background-color: #f7fbff;
  border: 1px;
  position: absolute;
  top: 1016px;
  left: 0;
}

.vector-21 {
  height: 1px;
  width: 64px;
  position: absolute;
  top: 66px;
  left: 684px;
}

.group-39626 {
  height: 693px;
  min-width: 1068px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 208px;
  left: 186px;
}

.mask-group-7 {
  height: 693px;
  width: 500px;
}

.group-39625 {
  min-height: 495px;
  width: 500px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 68px;
  display: flex;
}

.group-39624 {
  min-height: 92px;
  width: 270px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-90 {
  letter-spacing: 0;
  min-height: 50px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 50px;
}

.sub-headings-91 {
  letter-spacing: 0;
  min-height: 38px;
  white-space: nowrap;
  margin-top: 4px;
  line-height: 38px;
}

.line-448 {
  height: 1px;
  width: 500px;
  margin-top: 56px;
}

.group-39623 {
  min-height: 291px;
  width: 500px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 55px;
  display: flex;
}

.group-38084 {
  min-height: 199px;
  width: 500px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-92 {
  letter-spacing: 0;
  min-height: 34px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 34px;
}

.sub-headings-93 {
  letter-spacing: 0;
  min-height: 151px;
  width: 500px;
  margin-top: 14px;
  line-height: 30px;
}

.group-2793-10 {
  height: 38px;
  min-width: 200px;
  align-items: flex-start;
  margin-top: 54px;
  display: flex;
}

.group-2787-10 {
  height: 37px;
  min-width: 37px;
  background-color: #1781fd;
  border-radius: 136.35px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 0;
  padding: 8.2px 12.9px;
  display: flex;
  box-shadow: 0 10.45px 14.37px #1574ef36;
}

.icon-facebook-10 {
  height: 21px;
  width: 11px;
}

.group-75-3 {
  height: 37px;
  min-width: 37px;
  background-color: #009eff;
  border-radius: 136.35px;
  align-items: flex-end;
  margin-left: 17px;
  padding: 10.5px 8.6px;
  display: flex;
  box-shadow: 0 10.45px 14.37px #1abaff45;
}

.vector-20 {
  height: 16px;
  width: 20px;
}

.group-73-2 {
  height: 37px;
  min-width: 37px;
  background: radial-gradient(50% 50%, #fa8f21 9%, #eb2482 78%);
  border-radius: 136.35px;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
  margin-left: 17px;
  padding: 7.6px 7.5px;
  display: flex;
  box-shadow: 0 10.45px 14.37px #fa0e5a24;
}

.vector-22 {
  height: 22px;
  width: 22px;
}

.group-78-4 {
  height: 37px;
  min-width: 37px;
  background-color: #157de6;
  border-radius: 136.35px;
  align-items: flex-end;
  margin-left: 17px;
  padding: 9.5px 9.1px;
  display: flex;
  box-shadow: 0 10.45px 14.37px #147ee757;
}

.icon-linkedin-10 {
  height: 18px;
  width: 19px;
}

.group-39558 {
  height: 537px;
  min-width: 1174px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 1175px;
  left: 133px;
}

.articles-by-michael {
  letter-spacing: 0;
  min-height: 50px;
  min-width: 322px;
  white-space: nowrap;
  margin-top: 6.83px;
  line-height: 50px;
}

.primary-btn-29 {
  height: 64px;
  min-width: 225px;
  background-color: #103da0;
  border: 1px;
  border-radius: 80px;
  align-items: flex-start;
  margin-left: 622px;
  padding: 21px 40px;
  display: flex;
}

.button-text-31 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 145px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-31 {
  height: 64px;
  min-width: 189px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 46px;
  margin-left: 1px;
  padding: 21px 40px;
  display: flex;
}

.button-text-33 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 109px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-31.primary-btn-32 {
  margin-left: unset;
  margin-top: 49px;
}

.polis-about {
  height: 5610px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group3-4 {
  height: 1006px;
  width: 1674px;
  align-self: flex-start;
  margin-top: -68px;
  margin-left: -201.73px;
  position: relative;
}

.overlap-group2-6 {
  height: 1007px;
  width: 1668px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle-4097 {
  height: 767px;
  width: 1441px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 164px;
  left: 201px;
}

.ellipse-116 {
  height: 403px;
  width: 402px;
  background-color: #ffd600;
  border: .98px;
  border-radius: 201px / 201.5px;
  position: absolute;
  top: 163px;
  left: 1px;
  transform: rotate(90deg);
}

.ellipse-115 {
  height: 137px;
  width: 275px;
  position: absolute;
  top: 794px;
  left: 718px;
}

.ellipse-114-1 {
  height: 328px;
  width: 327px;
  background-color: #ff6262;
  border: .98px;
  border-radius: 163.5px / 164px;
  position: absolute;
  top: 0;
  left: 1340px;
  transform: rotate(90deg);
}

.rectangle-1513-1 {
  height: 334px;
  width: 334px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 597px;
  left: 1308px;
}

.rectangle-4105 {
  height: 334px;
  width: 334px;
  position: absolute;
  top: 597px;
  left: 1308px;
}

.sub-headings-95 {
  letter-spacing: 0;
  width: 536px;
  line-height: 61px;
  position: absolute;
  top: 401px;
  left: 955px;
}

.sub-headings-96 {
  letter-spacing: 1.6px;
  white-space: nowrap;
  line-height: 18px;
  position: absolute;
  top: 361px;
  left: 955px;
}

.sub-headings-97 {
  letter-spacing: 0;
  width: 536px;
  line-height: 30px;
  position: absolute;
  top: 606px;
  left: 955px;
}

.mask-group-8 {
  height: 732px;
  width: 520px;
  position: absolute;
  top: 275px;
  left: 335px;
}

.vector-21-1 {
  height: 1px;
  width: 64px;
  position: absolute;
  top: 134px;
  left: 885px;
}

.our-name {
  min-height: 244px;
  width: 749px;
  flex-direction: column;
  align-items: center;
  margin-top: 108px;
  margin-right: 27px;
  display: flex;
}

.our-name-1 {
  letter-spacing: 1.6px;
  min-height: 18px;
  min-width: 103px;
  text-align: center;
  white-space: nowrap;
  margin-top: -1px;
  margin-right: 6px;
  line-height: 18px;
}

.polis-means-city-i {
  letter-spacing: 0;
  min-height: 100px;
  min-width: 743px;
  text-align: center;
  margin-top: 14px;
  margin-right: 6px;
  line-height: 50px;
}

.sub-headings-98 {
  letter-spacing: 0;
  min-height: 90px;
  text-align: center;
  width: 530px;
  margin-top: 22px;
  margin-right: 7px;
  line-height: 30px;
}

.our-mission {
  min-width: 1172px;
  align-items: center;
  margin-top: 108px;
  margin-right: 3.46px;
  display: flex;
}

.group-39349 {
  min-height: 446px;
  width: 527px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5px;
  display: flex;
  position: relative;
}

.group-39343 {
  min-height: 336px;
  width: 533px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-94 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 18px;
}

.sub-headings-99 {
  letter-spacing: 0;
  min-height: 150px;
  width: 527px;
  margin-top: 20px;
  line-height: 50px;
}

.sub-headings-100 {
  letter-spacing: 0;
  min-height: 120px;
  width: 527px;
  margin-top: 28px;
  line-height: 30px;
}

.group-39544 {
  height: 625px;
  width: 525px;
  background-size: 100% 100%;
  margin-left: 120px;
}

.our-story {
  min-width: 1174px;
  align-items: center;
  margin-top: 147px;
  margin-right: .36px;
  display: flex;
}

.mask-group-9 {
  height: 625px;
  width: 574px;
}

.group-39316 {
  min-height: 485px;
  width: 535px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 36.16px;
  margin-left: 72px;
  display: flex;
  position: relative;
}

.sub-headings-101 {
  letter-spacing: 0;
  min-height: 50px;
  white-space: nowrap;
  margin-top: 20px;
  line-height: 50px;
}

.sub-headings-102 {
  letter-spacing: 0;
  min-height: 90px;
  width: 527px;
  margin-top: 34px;
  line-height: 30px;
}

.line-455 {
  height: 1px;
  width: 527px;
  margin-top: 36px;
  margin-left: .37px;
}

.sub-headings-103 {
  letter-spacing: 0;
  min-height: 90px;
  width: 527px;
  margin-top: 33px;
  line-height: 30px;
}

.meet-the-team {
  height: 622px;
  min-width: 1171px;
  border: 1px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 394px;
  margin-right: 1px;
  display: flex;
}

.overlap-group1-8 {
  height: 920px;
  width: 1442px;
  margin-top: -265px;
  position: relative;
}

.group-39247 {
  height: 470px;
  min-width: 1442px;
  background-color: #0d2c6f;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.e-container-2 {
  min-height: 470px;
  width: 205px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -.48px;
  display: flex;
}

.rectangle-4108-2 {
  height: 205px;
  width: 205px;
}

.ellipse-412-1 {
  height: 264px;
  width: 131px;
  margin-top: 1px;
  margin-left: 0;
}

.e-container-3 {
  height: 470px;
  width: 264px;
  margin-bottom: -.49px;
  margin-left: 971px;
  position: relative;
}

.ellipse-413-3 {
  height: 264px;
  width: 263px;
  background-color: #132999;
  border: .77px;
  border-radius: 131.45px / 131.84px;
  position: absolute;
  top: 207px;
  left: 0;
  transform: rotate(90deg);
}

.rectangle-4114-2 {
  height: 208px;
  width: 208px;
  background-color: #103da0;
  border: .62px;
  position: absolute;
  top: 0;
  left: 56px;
}

.ellipse-419-1 {
  height: 206px;
  width: 206px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 102.9px;
  position: absolute;
  top: 0;
  left: 56px;
}

.sub-headings-104 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 540px;
  line-height: 50px;
  position: absolute;
  top: 164px;
  left: 450px;
}

.sub-headings-105 {
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  line-height: 18px;
  position: absolute;
  top: 126px;
  left: 669px;
}

.why-polis {
  height: 1070px;
  min-width: 1440px;
  background-color: #f7fbff;
  align-items: flex-start;
  margin-top: 136px;
  padding: 96.5px 132px;
  display: flex;
}

.flex-col-12 {
  min-height: 508px;
  width: 502px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 36.53px;
  display: flex;
}

.text {
  min-height: 392px;
  width: 502px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.why-polis-1 {
  letter-spacing: 0;
  min-height: 61px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 61px;
}

.sub-headings-106 {
  letter-spacing: 0;
  min-height: 300px;
  width: 498px;
  margin-top: 31px;
  line-height: 30px;
}

.primary-btn-30 {
  height: 64px;
  min-width: 365px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 52px;
  margin-left: 1px;
  padding: 21px 40px;
  display: flex;
}

.button-text-32 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 285px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.images {
  min-height: 800px;
  width: 573px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 98px;
  padding: 0;
  display: flex;
}

.mask-group-10 {
  height: 379px;
  width: 573px;
}

.mask-group-11 {
  height: 379px;
  width: 573px;
  margin-top: 41px;
}

.group-37933 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-111 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.overlap-group-17 {
  height: 62px;
  min-width: 230px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 13px;
  padding: 19px;
  display: flex;
}

.sub-headings-112 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.group-37933.group-37941, .group-37933.group-37941-1 {
  margin-top: 30px;
}

.group-37931 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-115 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.overlap-group-19 {
  height: 62px;
  min-width: 230px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 13px;
  padding: 19px 21.1px;
  display: flex;
}

.sub-headings-116 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.group-37931.group-37930, .group-37931.group-37930-1 {
  margin-top: 30px;
  margin-left: 0;
}

.group-37931.group-37930 .overlap-group-19, .group-37931.group-37930-1 .overlap-group-19 {
  padding: 20.5px 21.1px;
}

.primary-btn-33 {
  height: 64px;
  min-width: 179px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-self: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  margin-left: 56.5px;
  padding: 21px 40px;
  display: flex;
}

.button-text-35 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 99px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.primary-btn-33.primary-btn-34 {
  align-self: unset;
  margin-left: unset;
  margin-top: unset;
}

.polis-contact {
  height: 1586px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group4-5 {
  height: 994px;
  width: 1440px;
  position: relative;
}

.follow-us {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 34px;
  position: absolute;
  top: 923px;
  left: 133px;
}

.contact-form {
  height: 900px;
  min-width: 1440px;
  background-color: #f7fbff;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: 94px;
  left: 0;
}

.group-39523 {
  min-height: 264px;
  width: 533px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 192px;
  display: flex;
}

.sub-headings-108 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 18px;
}

.sub-headings-container-2 {
  height: 226px;
  width: 527px;
  margin-top: 20px;
  position: relative;
}

.sub-headings-109 {
  letter-spacing: 0;
  width: 494px;
  line-height: 30px;
  position: absolute;
  top: 136px;
  left: 0;
}

.sub-headings-110 {
  letter-spacing: 0;
  width: 527px;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group3-5 {
  height: 900px;
  width: 735px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 39px;
  position: relative;
}

.rectangle-4097-1 {
  height: 900px;
  width: 461px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 0;
  left: 274px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.rectangle-4032-1 {
  height: 290px;
  width: 290px;
  position: absolute;
  top: 610px;
  left: 445px;
}

.ellipse-407 {
  height: 318px;
  width: 318px;
  position: absolute;
  top: 0;
  left: 415px;
}

.group-38113 {
  min-height: 739px;
  width: 600px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 62.9px 0;
  display: flex;
  position: absolute;
  top: 80px;
  left: 0;
}

.group-37882 {
  height: 342px;
  min-width: 490px;
  align-items: flex-start;
  margin-right: 0;
  display: flex;
}

.group-container-12 {
  min-height: 342px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-37940 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  display: flex;
}

.sub-headings-107 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.group-3-1 {
  height: 62px;
  min-width: 230px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 13px;
  padding: 19px 20.7px;
  display: flex;
}

.select-service {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 111px;
  line-height: 20px;
}

.vector-23 {
  height: 5px;
  width: 11px;
  align-self: center;
  margin-bottom: 2px;
  margin-left: 65px;
}

.group-container-13 {
  min-height: 342px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 28px;
  display: flex;
  position: relative;
}

.group-37939 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  margin-left: 0;
  display: flex;
}

.budget {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 57px;
  line-height: 20px;
}

.vector-24 {
  height: 5px;
  width: 11px;
  align-self: center;
  margin-bottom: 2px;
  margin-left: 119px;
}

.overlap-group1-9 {
  height: 147px;
  min-width: 490px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 30px;
  padding: 15.3px 24px;
  display: flex;
}

.describe-your-project {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.rectangle-15 {
  height: 91px;
  width: 390px;
  position: absolute;
  top: 650px;
  left: 131px;
}

.group-38121 {
  height: 48px;
  min-width: 275px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 671px;
  left: 155px;
}

.group-38117 {
  height: 48px;
  min-width: 48px;
  background-color: #103da026;
  border-radius: 13.74px;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 13px 10.2px;
  display: flex;
}

.overlap-group-16 {
  height: 22px;
  min-width: 28px;
  background-size: 100% 100%;
  align-items: flex-start;
  display: flex;
}

.vector-25 {
  height: 11px;
  width: 28px;
}

.heading-h2-3 {
  letter-spacing: .36px;
  min-height: 20px;
  min-width: 199px;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 26px;
  line-height: 20px;
}

.avatar-sophie-moore-circle-small {
  min-width: 54px;
  background-size: 100% 100%;
  border: .92px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 9px;
  display: flex;
}

.mask-group-18 {
  height: 54px;
  width: 54px;
  margin-top: 0;
}

.avatar-sophie-moore-circle-small.avatar-andy-smith-circle-small {
  border: .93px;
  align-items: flex-end;
  margin-top: 12px;
  margin-left: 0;
}

.avatar-sophie-moore-circle-small.avatar-andy-smith-circle-small .mask-group-18 {
  margin-bottom: 0;
  margin-top: unset;
}

.group-393-1 {
  height: 240px;
  min-width: 574px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 24px;
  align-items: center;
  margin-top: 24px;
  padding: 25px 38px;
  display: flex;
  box-shadow: 0 2.15px 10.77px #1e245914;
}

.group-38028-7 {
  height: 86px;
  width: 86px;
  background-color: #6935ff1a;
  border: .7px solid #103da01a;
  border-radius: 15.46px;
  margin-bottom: 42px;
}

.sub-headings-container-9 {
  height: 164px;
  width: 383px;
  align-self: flex-end;
  margin-left: 26px;
  position: relative;
}

.sub-headings-153 {
  letter-spacing: 0;
  width: 383px;
  line-height: 30px;
  position: absolute;
  top: 44px;
  left: 0;
}

.sub-headings-154 {
  letter-spacing: 0;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-393-1.group-39308 {
  margin-top: unset;
}

.group-393-1.group-39308 .group-38028-7 {
  border: unset;
  border-color: unset;
  background-color: #ffd6001a;
}

.group-393-1.group-393 .group-38028-7 {
  border: unset;
  border-color: unset;
  background-color: #ff62621a;
}

.polis-home {
  min-height: 8291px;
  width: 1440px;
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.overlap-group-container-2 {
  height: 1711px;
  width: 1444px;
  margin-right: -4px;
  position: relative;
}

.overlap-group7-3 {
  min-height: 865px;
  width: 1440px;
  background-color: #f7fbff;
  flex-direction: column;
  align-items: center;
  padding: 124.5px 132.7px;
  display: flex;
  position: absolute;
  top: 846px;
  left: 0;
}

.sub-headings-124 {
  letter-spacing: 1.6px;
  min-height: 18px;
  text-align: center;
  white-space: nowrap;
  width: 167px;
  margin-right: 1px;
  line-height: 18px;
}

.sub-headings-125 {
  letter-spacing: 0;
  min-height: 100px;
  text-align: center;
  width: 527px;
  margin-top: 18px;
  margin-right: .67px;
  line-height: 50px;
}

.group-39498 {
  min-width: 1173px;
  align-self: flex-start;
  align-items: flex-start;
  margin-top: 59px;
  display: flex;
}

.service-1 {
  height: 420px;
  min-width: 374px;
  border: 1px;
  align-self: flex-end;
  align-items: flex-start;
  display: flex;
}

.overlap-group-20 {
  min-height: 420px;
  width: 374px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  padding: 15.4px 26.3px;
  display: flex;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-38028-3 {
  height: 89px;
  width: 86px;
  background-color: #ff62621a;
  border-radius: 15.46px;
  margin-top: 50px;
  margin-right: 2px;
}

.sub-headings-126 {
  letter-spacing: 0;
  min-height: 34px;
  min-width: 202px;
  text-align: center;
  white-space: nowrap;
  margin-top: 24px;
  margin-right: 3px;
  line-height: 34px;
}

.overlap-group1-11 {
  height: 180px;
  width: 321px;
  align-self: flex-start;
  margin-top: 12px;
  position: relative;
}

.sub-headings-127 {
  letter-spacing: 0;
  text-align: center;
  width: 321px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.line-451-1 {
  height: 1px;
  width: 110px;
  position: absolute;
  top: 155px;
  left: 106px;
}

.service-2 {
  height: 406px;
  min-width: 374px;
  border: 1px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: .29px;
  margin-left: 26px;
  display: flex;
}

.group-container-14 {
  min-height: 420px;
  width: 374px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -13.49px;
  padding: 22.5px 26.5px;
  display: flex;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-38028-4 {
  height: 86px;
  width: 86px;
  background-color: #ffd6001a;
  border-radius: 15.46px;
  align-self: center;
  margin-top: 41px;
  margin-right: 2.15px;
}

.overlap-group1-10 {
  height: 224px;
  width: 321px;
  margin-top: 24px;
  position: relative;
}

.sub-headings-119 {
  letter-spacing: 0;
  text-align: center;
  width: 321px;
  line-height: 30px;
  position: absolute;
  top: 44px;
  left: 0;
}

.sub-headings-128 {
  letter-spacing: 0;
  text-align: center;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 33px;
}

.line-451-2 {
  height: 1px;
  width: 110px;
  position: absolute;
  top: 207px;
  left: 101px;
}

.service-3 {
  height: 406px;
  min-width: 374px;
  border: 1px;
  align-items: flex-end;
  margin-left: 26px;
  display: flex;
}

.group-container-15 {
  min-height: 420px;
  width: 374px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: -13.6px;
  padding: 22.8px 26.3px;
  display: flex;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.group-38028-5 {
  height: 86px;
  width: 86px;
  background-color: #103da01a;
  border-radius: 15.46px;
  align-self: center;
  margin-top: 40px;
  margin-right: 2px;
}

.sub-headings-129 {
  letter-spacing: 0;
  text-align: center;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 41px;
}

.line-451-3 {
  height: 1px;
  width: 110px;
  position: absolute;
  top: 207px;
  left: 106px;
}

.overlap-group-container-3 {
  height: 846px;
  width: 1307px;
  position: absolute;
  top: 0;
  left: 133px;
}

.overlap-group8-2 {
  height: 846px;
  width: 741px;
  position: absolute;
  top: 0;
  left: 566px;
}

.rectangle-1512-1 {
  height: 846px;
  width: 554px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 0;
  left: 187px;
}

.ellipse-114-2 {
  height: 187px;
  width: 375px;
  position: absolute;
  top: 659px;
  left: 0;
}

.rectangle-1513-2 {
  height: 284px;
  width: 284px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 0;
  left: 187px;
}

.rectangle-4050 {
  height: 284px;
  width: 284px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 562px;
  left: 457px;
}

.rectangle-1514-1 {
  height: 269px;
  width: 269px;
  position: absolute;
  top: 0;
  left: 472px;
}

.overlap-group1-12 {
  height: 557px;
  width: 565px;
  position: absolute;
  top: 145px;
  left: 41px;
}

.rectangle-4049 {
  height: 557px;
  width: 502px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 18.54px;
  position: absolute;
  top: 0;
  left: 63px;
  box-shadow: 0 18px 54px #14132a12;
}

.ellipse-429 {
  height: 14px;
  width: 14px;
  background-color: #ff6262;
  border: 1px;
  border-radius: 7px;
  position: absolute;
  top: 21px;
  left: 87px;
}

.ellipse-430 {
  height: 14px;
  width: 14px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 7px;
  position: absolute;
  top: 21px;
  left: 109px;
}

.ellipse-431 {
  height: 14px;
  width: 14px;
  background-color: #103da0;
  border: 1px;
  border-radius: 7px;
  position: absolute;
  top: 21px;
  left: 131px;
}

.rectangle-4409 {
  height: 199px;
  width: 454px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 13px;
  position: absolute;
  top: 58px;
  left: 87px;
}

.group-39566 {
  min-height: 255px;
  width: 253px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 13px;
  flex-direction: column;
  align-items: flex-start;
  padding: 21.4px 18.4px;
  display: flex;
  position: absolute;
  top: 274px;
  left: 87px;
}

.vector-container {
  height: 166px;
  width: 216px;
  position: relative;
}

.vector-15-1 {
  height: 166px;
  width: 204px;
  position: absolute;
  top: 0;
  left: 6px;
}

.vector-16-1 {
  height: 114px;
  width: 216px;
  position: absolute;
  top: 40px;
  left: 0;
}

.group-39562 {
  min-width: 130px;
  align-items: center;
  margin-top: 25px;
  margin-left: 1.66px;
  display: flex;
}

.ellipse-432 {
  height: 13px;
  width: 13px;
  background-color: #103da0;
  border: 1px;
  border-radius: 6.58px;
}

.rectangle-4432 {
  height: 5px;
  opacity: .5;
  width: 52px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 4px;
}

.ellipse-433 {
  height: 13px;
  width: 13px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 6.58px;
  margin-left: 16px;
}

.rectangle-4433 {
  height: 5px;
  opacity: .5;
  width: 29px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 4px;
}

.group-39567 {
  min-height: 255px;
  width: 185px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 13px;
  flex-direction: column;
  align-items: flex-start;
  padding: 28.2px 26px;
  display: flex;
  position: absolute;
  top: 274px;
  left: 356px;
}

.group-39555 {
  height: 113px;
  min-width: 113px;
  align-self: center;
  align-items: flex-start;
  margin-right: 2px;
  display: flex;
}

.ellipse-container {
  height: 125px;
  width: 125px;
  border-radius: 62.63px;
  margin-top: -6.36px;
  margin-left: -6px;
  position: relative;
}

.ellipse-49 {
  height: 125px;
  width: 125px;
  border: 12.72px solid #103da0;
  border-radius: 62.63px;
  position: absolute;
  top: 0;
  left: 0;
}

.ellipse-50 {
  height: 125px;
  width: 125px;
  border: 12.72px solid #ffd600;
  border-radius: 62.63px;
  position: absolute;
  top: 0;
  left: 0;
}

.ellipse-48 {
  height: 125px;
  width: 125px;
  border: 12.72px solid #ff6262;
  border-radius: 62.63px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-39559 {
  min-height: 67px;
  width: 131px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 17px;
  display: flex;
}

.group-39552 {
  min-width: 111px;
  align-items: center;
  display: flex;
}

.ellipse-432-1 {
  height: 17px;
  width: 17px;
  background-color: #103da0;
  border: 1px;
  border-radius: 8.51px;
}

.rectangle-4432-1 {
  height: 7px;
  opacity: .5;
  width: 90px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 5px;
}

.group-39551 {
  min-width: 72px;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.ellipse-433-1 {
  height: 17px;
  width: 17px;
  background-color: #ff6262;
  border: 1px;
  border-radius: 8.51px;
}

.rectangle-4433-1 {
  height: 7px;
  opacity: .5;
  width: 50px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 5px;
}

.group-39550 {
  min-width: 131px;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.ellipse-434 {
  height: 17px;
  width: 17px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 8.51px;
}

.rectangle-4434 {
  height: 7px;
  opacity: .5;
  width: 107px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 7px;
}

.group-39553 {
  min-height: 139px;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 88px;
  left: 113px;
}

.group-39549 {
  height: 109px;
  width: 398px;
  margin-left: 1.69px;
}

.group-39554 {
  min-width: 226px;
  align-items: flex-start;
  margin-top: 17px;
  display: flex;
}

.group-39552-1 {
  min-width: 69px;
  align-items: center;
  display: flex;
}

.group-39551-1 {
  min-width: 46px;
  align-items: center;
  margin-left: 16px;
  display: flex;
}

.ellipse-433-2 {
  height: 13px;
  width: 13px;
  background-color: #ff6262;
  border: 1px;
  border-radius: 6.58px;
}

.group-39550-1 {
  min-width: 80px;
  align-items: center;
  margin-left: 16px;
  display: flex;
}

.ellipse-434-1 {
  height: 13px;
  width: 13px;
  background-color: #ffd600;
  border: 1px;
  border-radius: 6.58px;
}

.rectangle-4434-1 {
  height: 5px;
  opacity: .5;
  width: 62px;
  background-color: #dbddea;
  border: 1px;
  border-radius: 30px;
  margin-bottom: 0;
  margin-left: 5px;
}

.group-39569 {
  height: 238px;
  min-width: 238px;
  background-color: #fff;
  border: 1px solid #eff0f6;
  border-radius: 18.54px;
  align-items: flex-start;
  padding: 25.2px 23.1px;
  display: flex;
  position: absolute;
  top: 206px;
  left: 0;
  box-shadow: 0 18px 54px #14132a12;
}

.circle-small-container {
  min-height: 181px;
  width: 54px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.avatar-mike-warren-circle-small {
  min-width: 52px;
  background-size: 100% 100%;
  border: .9px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 0;
  display: flex;
}

.mask-group-13 {
  height: 52px;
  width: 52px;
  margin-bottom: -.01px;
}

.group-container-16 {
  min-height: 153px;
  width: 90px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-bottom: 7.6px;
  margin-left: 9px;
  display: flex;
}

.group-4233 {
  min-height: 26px;
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.rectangle-1283 {
  height: 9px;
  opacity: .8;
  width: 53px;
  background-color: #bbbed1;
  border: 1.53px;
  border-radius: 45.88px;
}

.rectangle-1284 {
  height: 9px;
  opacity: .4;
  width: 90px;
  background-color: #dbddea;
  border: 1.53px;
  border-radius: 45.88px;
  margin-top: 7px;
  margin-left: 0;
}

.group-4234 {
  min-height: 26px;
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 36px;
  display: flex;
}

.rectangle-1283-1 {
  height: 9px;
  opacity: .8;
  width: 90px;
  background-color: #bbbed1;
  border: 1.53px;
  border-radius: 45.88px;
}

.rectangle-1284-1 {
  height: 9px;
  opacity: .4;
  width: 53px;
  background-color: #dbddea;
  border: 1.53px;
  border-radius: 45.88px;
  margin-top: 7px;
  margin-left: 0;
}

.group-4235 {
  min-height: 26px;
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.rectangle-1283-2 {
  height: 9px;
  opacity: .8;
  width: 31px;
  background-color: #bbbed1;
  border: 1.53px;
  border-radius: 45.88px;
}

.overlap-group9-3 {
  height: 484px;
  width: 606px;
  position: absolute;
  top: 145px;
  left: 0;
}

.group-38091 {
  min-height: 484px;
  width: 606px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.sub-headings-130 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  margin-top: -1px;
  margin-left: .35px;
  line-height: 18px;
}

.sub-headings-131 {
  letter-spacing: 0;
  min-height: 180px;
  width: 600px;
  margin-top: 21px;
  margin-left: .35px;
  line-height: 60px;
}

.sub-headings-132 {
  letter-spacing: 0;
  min-height: 120px;
  width: 523px;
  margin-top: 27px;
  margin-left: .35px;
  line-height: 30px;
}

.frame-35997 {
  height: 64px;
  width: 269px;
  border: 1px;
  margin-top: 55px;
}

.primary-btn-36 {
  height: 64px;
  min-width: 269px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  padding: 21px 40px;
  display: flex;
  position: absolute;
  top: 388px;
  left: 0;
}

.button-text-36 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 189px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.about-us {
  min-height: 948px;
  width: 1181px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 121px;
  margin-left: 7.23px;
  display: flex;
}

.flex-row-25 {
  height: 138px;
  min-width: 1179px;
  align-items: flex-start;
  margin-right: 1.98px;
  display: flex;
}

.group-38147 {
  min-height: 138px;
  width: 389px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-120 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 18px;
}

.sub-headings-133 {
  letter-spacing: 0;
  min-height: 100px;
  width: 385px;
  margin-top: 20px;
  line-height: 50px;
}

.sub-headings-134 {
  letter-spacing: 0;
  min-height: 90px;
  width: 525px;
  margin-top: 8px;
  margin-left: 265px;
  line-height: 30px;
}

.mask-group-container {
  height: 618px;
  width: 1174px;
  margin-top: 61px;
  margin-right: 7.27px;
  position: relative;
}

.mask-group-14 {
  height: 540px;
  width: 674px;
  position: absolute;
  top: 0;
  left: 0;
}

.mask-group-15 {
  height: 545px;
  width: 700px;
  position: absolute;
  top: 73px;
  left: 474px;
}

.primary-btn-37 {
  height: 64px;
  min-width: 173px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 67px;
  margin-right: 15.73px;
  padding: 21px 40px;
  display: flex;
}

.button-text-37 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 93px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.overlap-group13-1 {
  height: 2074px;
  width: 1609px;
  margin-top: 119px;
  margin-right: -168.7px;
  position: relative;
}

.rectangle-4051-2 {
  height: 1038px;
  width: 1440px;
  background-color: #f7fbff;
  border: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-38147-1 {
  min-height: 188px;
  width: 531px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 127px;
  left: 133px;
}

.sub-headings-135 {
  letter-spacing: 0;
  min-height: 150px;
  width: 527px;
  margin-top: 20px;
  line-height: 50px;
}

.frame-35998 {
  height: 64px;
  min-width: 411px;
  border: 1px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 251px;
  left: 893px;
}

.group-39448 {
  height: 504px;
  min-width: 1174px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 389px;
  left: 133px;
}

.group-container-17 {
  min-height: 504px;
  width: 574px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-39307 {
  height: 240px;
  min-width: 574px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 24px;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 37px;
  display: flex;
  box-shadow: 0 2.15px 10.77px #1e245914;
}

.group-38028-6 {
  height: 86px;
  width: 86px;
  background-color: #ff62621a;
  border-radius: 15.46px;
  margin-bottom: 42px;
}

.group-39305 {
  min-height: 164px;
  width: 387px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  margin-left: 26px;
  display: flex;
}

.sub-headings-136 {
  letter-spacing: 0;
  min-height: 34px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 34px;
}

.sub-headings-137 {
  letter-spacing: 0;
  min-height: 120px;
  width: 383px;
  margin-top: 10px;
  line-height: 30px;
}

.group-container-18 {
  min-height: 504px;
  width: 574px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 26px;
  display: flex;
  position: relative;
}

.overlap-group11-1 {
  height: 1038px;
  width: 1607px;
  position: absolute;
  top: 1036px;
  left: 0;
}

.rectangle-4052 {
  height: 1038px;
  width: 1440px;
  background-color: #0d2c6f;
  border: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-39600 {
  min-height: 128px;
  width: 531px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 121px;
  left: 135px;
}

.sub-headings-138 {
  letter-spacing: 0;
  min-height: 100px;
  width: 527px;
  margin-top: 10px;
  line-height: 50px;
}

.testimonials {
  height: 414px;
  min-width: 474px;
  background-color: #eff0f6;
  border-radius: 24px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 73.2px 40px;
  display: flex;
  position: absolute;
  top: 289px;
  left: 133px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.overlap-group1-13 {
  height: 266px;
  width: 386px;
  position: relative;
}

.group-37846 {
  height: 62px;
  min-width: 163px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 204px;
  left: 0;
}

.mask-group-12 {
  height: 62px;
  width: 62px;
}

.sub-headings-container-3 {
  height: 44px;
  width: 89px;
  margin-bottom: 2px;
  margin-left: 12px;
  position: relative;
}

.sub-headings-121 {
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.sub-headings-122 {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 20px;
  position: absolute;
  top: 24px;
  left: 0;
}

.sub-headings-139 {
  letter-spacing: 0;
  width: 386px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-37885 {
  min-height: 414px;
  width: 474px;
  background-color: #eff0f6;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  padding: 73.4px 40px;
  display: flex;
  position: absolute;
  top: 289px;
  left: 633px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.sub-headings-140 {
  letter-spacing: 0;
  min-height: 170px;
  min-width: 386px;
  align-self: flex-end;
  line-height: 34px;
}

.group-37846-1 {
  min-width: 176px;
  align-items: center;
  margin-top: 34px;
  margin-left: 8px;
  display: flex;
}

.sub-headings-container-4 {
  height: 44px;
  width: 102px;
  margin-bottom: 2px;
  margin-left: 12px;
  position: relative;
}

.group-37886 {
  min-height: 414px;
  width: 474px;
  background-color: #eff0f6;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  padding: 73.4px 45px;
  display: flex;
  position: absolute;
  top: 289px;
  left: 1133px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.sub-headings-141 {
  letter-spacing: 0;
  min-height: 170px;
  min-width: 381px;
  align-self: flex-end;
  line-height: 34px;
}

.group-37846-2 {
  min-width: 188px;
  align-items: center;
  margin-top: 34px;
  margin-left: 3px;
  display: flex;
}

.sub-headings-container-5 {
  height: 44px;
  width: 114px;
  margin-bottom: 2px;
  margin-left: 12px;
  position: relative;
}

.sub-headings-142 {
  letter-spacing: 0;
  width: 527px;
  line-height: 30px;
  position: absolute;
  top: 176px;
  left: 780px;
}

.educators {
  min-width: 1172px;
  align-self: center;
  align-items: center;
  margin-top: 127px;
  margin-right: 2px;
  display: flex;
}

.group-39349-1 {
  min-height: 368px;
  width: 527px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 58.5px;
  display: flex;
}

.group-39343-1 {
  min-height: 252px;
  width: 533px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-143 {
  letter-spacing: 0;
  min-height: 100px;
  width: 527px;
  margin-top: 20px;
  line-height: 50px;
}

.sub-headings-144 {
  letter-spacing: 0;
  min-height: 90px;
  width: 527px;
  margin-top: 24px;
  line-height: 30px;
}

.primary-btn-38 {
  height: 64px;
  min-width: 246px;
  background-color: #103da0;
  border: 1px;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 52px;
  margin-left: 1px;
  padding: 21px 40px;
  display: flex;
}

.button-text-38 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 166px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.group-container-19 {
  height: 685px;
  width: 590px;
  margin-left: 55px;
  position: relative;
}

.mask-group-16 {
  height: 625px;
  width: 525px;
  position: absolute;
  top: 0;
  left: 65px;
}

.group-39344 {
  min-height: 277px;
  width: 430px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  padding: 49px 48px;
  display: flex;
  position: absolute;
  top: 408px;
  left: 0;
  box-shadow: 0 2.15px 10.77px #1e245914;
}

.sub-headings-145 {
  letter-spacing: 0;
  min-height: 90px;
  width: 333px;
  line-height: 30px;
}

.group-39192 {
  min-width: 284px;
  align-items: center;
  margin-top: 19px;
  margin-left: .37px;
  display: flex;
}

.mask-group-17 {
  height: 68px;
  width: 68px;
}

.flex-col-13 {
  min-height: 50px;
  width: 193px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2px;
  margin-left: 19px;
  display: flex;
}

.sub-headings-146, .sub-headings-151, .describe-your-project-1 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  line-height: 20px;
}

.ceo-at-marketingly-x {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: 10px;
  line-height: 20px;
}

.resources {
  min-height: 611px;
  width: 1578px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 127px;
  margin-right: -270.98px;
  display: flex;
}

.flex-row-26 {
  height: 138px;
  min-width: 1128px;
  align-items: flex-end;
  margin-top: -1px;
  display: flex;
  position: relative;
}

.sub-headings-container-6 {
  min-height: 138px;
  width: 444px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.sub-headings-147 {
  letter-spacing: 1.6px;
  min-height: 18px;
  white-space: nowrap;
  line-height: 18px;
}

.sub-headings-148 {
  letter-spacing: 0;
  min-height: 100px;
  width: 444px;
  margin-top: 20px;
  margin-left: .27px;
  line-height: 50px;
}

.secondary-btn-3 {
  height: 64px;
  min-width: 160px;
  border: 1px solid #103da0;
  border-radius: 10px;
  align-items: flex-start;
  margin-bottom: 11px;
  margin-left: 22px;
  padding: 21px 40px;
  display: flex;
}

.button-text-39 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 80px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
}

.flex-row-27 {
  min-width: 1574px;
  align-items: flex-start;
  margin-top: 49px;
  display: flex;
  position: relative;
}

.overlap-group5-4 {
  height: 425px;
  width: 400px;
  margin-left: 26px;
  position: relative;
}

.contact-form-1 {
  min-width: 1440px;
  background-color: #f7fbff;
  justify-content: flex-end;
  align-items: center;
  margin-top: 177px;
  display: flex;
}

.group-39523-1 {
  min-height: 398px;
  width: 480px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 58px;
  display: flex;
}

.sub-headings-container-7 {
  height: 226px;
  width: 474px;
  margin-top: 20px;
  position: relative;
}

.sub-headings-149 {
  letter-spacing: 0;
  width: 444px;
  line-height: 30px;
  position: absolute;
  top: 136px;
  left: 0;
}

.sub-headings-150 {
  letter-spacing: 0;
  width: 474px;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group3-6 {
  height: 97px;
  min-width: 300px;
  background-size: 100% 100%;
  align-items: flex-start;
  margin-top: 38px;
  margin-left: .02px;
  padding: 26px 22px;
  display: flex;
}

.group-39141 {
  height: 28px;
  width: 35px;
  align-self: center;
  margin-bottom: 1px;
}

.sub-headings-container-8 {
  min-height: 42px;
  width: 181px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 22px;
  display: flex;
}

.sub-headings-152 {
  letter-spacing: 0;
  min-height: 18px;
  white-space: nowrap;
  margin-top: 4px;
  line-height: 18px;
}

.overlap-group4-6 {
  height: 900px;
  width: 735px;
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: 92px;
  position: relative;
}

.rectangle-4097-2 {
  height: 900px;
  width: 461px;
  background-color: #103da0;
  border: 1px;
  position: absolute;
  top: 0;
  left: 274px;
  box-shadow: 0 4px 22px #080f3408, 0 -3px 8px #14132a03;
}

.rectangle-4032-2 {
  height: 290px;
  width: 290px;
  position: absolute;
  top: 610px;
  left: 445px;
}

.ellipse-407-1 {
  height: 318px;
  width: 318px;
  position: absolute;
  top: 0;
  left: 415px;
}

.group-38113-1 {
  min-height: 739px;
  width: 600px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  padding: 62.9px 0;
  display: flex;
  position: absolute;
  top: 80px;
  left: 0;
}

.group-37882-1 {
  height: 342px;
  min-width: 490px;
  align-items: flex-start;
  margin-right: 0;
  display: flex;
}

.group-container-20 {
  min-height: 342px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-37940-1 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  display: flex;
}

.sub-headings-123 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  line-height: 20px;
}

.group-3-2 {
  height: 62px;
  min-width: 230px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 13px;
  padding: 19px 20.7px;
  display: flex;
}

.select-service-1 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 111px;
  line-height: 20px;
}

.vector-26 {
  height: 5px;
  width: 11px;
  align-self: center;
  margin-bottom: 2px;
  margin-left: 65px;
}

.group-container-21 {
  min-height: 342px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 28px;
  display: flex;
  position: relative;
}

.group-37939-1 {
  min-height: 94px;
  width: 232px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  margin-left: 0;
  display: flex;
}

.budget-1 {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  width: 57px;
  line-height: 20px;
}

.vector-27 {
  height: 5px;
  width: 11px;
  align-self: center;
  margin-bottom: 2px;
  margin-left: 119px;
}

.overlap-group1-14 {
  height: 147px;
  min-width: 490px;
  background-color: #fff;
  border: 1px solid #dbddea;
  border-radius: 10px;
  align-items: flex-start;
  margin-top: 30px;
  padding: 15.3px 24px;
  display: flex;
}

/*# sourceMappingURL=index.8356f9ca.css.map */
