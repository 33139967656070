@import '../../variables'

.primary-btn-2
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-top: 33px
  min-width: 206px
  padding: 21px 40px

.button-text-2
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 126px
  text-align: center
  white-space: nowrap

.primary-btn-2.primary-btn-3
  margin-bottom: 11px
  margin-left: 296px
  margin-top: unset

