@import '../../variables'

.blog-v1-card-5
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 533px
  min-height: 425px
  position: absolute
  top: 1056px
  width: 374px

.overlap-group4
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38098
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 0
  margin-top: 26px
  min-height: 147px
  position: relative
  width: 376px

.aso-how-to-rank-you
  letter-spacing: 0
  line-height: 34px
  margin-left: 0
  margin-top: 18px
  min-height: 68px
  width: 374px

