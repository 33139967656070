.frame-35995-13
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-left: 0.04px
  min-width: 127px

.sub-headings-17
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.frame-35995-13.frame-35995-1
  margin-left: 46.47px

.frame-35995-13.frame-35995-2
  margin-left: 59.97px

.frame-35995-13.frame-35995-3,
.frame-35995-13.frame-35995-4
  align-self: center
  margin-left: unset
  margin-right: 83.06px

.frame-35995-13.frame-35995-5,
.frame-35995-13.frame-35995-8,
.frame-35995-13.frame-35995-11
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-left: 46.47px
  min-width: 127px

.frame-35995-13.frame-35995-6,
.frame-35995-13.frame-35995-9,
.frame-35995-13.frame-35995-12
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-left: 59.97px
  min-width: 127px

.frame-35995-13.frame-35995-7,
.frame-35995-13.frame-35995-10
  align-items: flex-start
  align-self: center
  border: 1px none
  display: flex
  height: 20px
  margin-right: 83.06px
  min-width: 127px

