@import '../../variables'

.primary-btn-27
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-top: 58px
  min-width: 219px
  padding: 21px 40px

.button-text-29
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 139px
  text-align: center
  white-space: nowrap

.primary-btn-27.primary-btn-28
  background-color: $carnation
  left: 609px
  margin-top: unset
  position: absolute
  top: 751px

