@import '../../variables'

.group-37853
  align-items: flex-end
  background-color: $torea-bay
  border-radius: 32px
  display: flex
  height: 64px
  left: 1271px
  min-width: 64px
  padding: 21.6px 21.1px
  position: absolute
  top: 488px

.group-7
  height: 21px
  width: 22px

.group-37853.group-37853-1
  background-color: $carnation
  box-shadow: 0px 6px 16px #4939ff30
  justify-content: flex-end
  left: 1274px
  top: 464px

.group-37853.group-39132
  justify-content: flex-end
  left: 336px
  top: 93px

