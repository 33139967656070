@import '../../variables'

.primary-btn-33
  align-items: flex-start
  align-self: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 56.5px
  margin-top: 30px
  min-width: 179px
  padding: 21px 40px

.button-text-35
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 99px
  text-align: center
  white-space: nowrap

.primary-btn-33.primary-btn-34
  align-self: unset
  margin-left: unset
  margin-top: unset

