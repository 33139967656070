@import '../../variables'

.blog-v1-card-4
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 133px
  min-height: 425px
  position: absolute
  top: 1056px
  width: 374px

.overlap-group2-1
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38097
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 0
  margin-top: 26px
  min-height: 147px
  padding: 0 0.0px
  position: relative
  width: 376px

.address-1
  letter-spacing: 0
  line-height: 34px
  margin-top: 18px
  min-height: 68px
  width: 374px

