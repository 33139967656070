.team-1
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 135px
  min-height: 1244px
  position: absolute
  top: 433px
  width: 1171px

.group-39355
  align-items: flex-start
  display: flex
  margin-left: -2px
  margin-top: 63px
  min-width: 1174px

.group-39332
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 591px
  width: 374px

.group-39333
  align-self: center
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 591px
  margin-left: 25px
  width: 376px

.group-39334
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 591px
  margin-left: 25px
  width: 374px

