@import '../../variables'

.polis-about
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 5610px
  overflow: hidden
  position: relative
  width: 1440px

.overlap-group3-4
  align-self: flex-start
  height: 1006px
  margin-left: -201.73px
  margin-top: -68px
  position: relative
  width: 1674px

.overlap-group2-6
  height: 1007px
  left: 0
  position: absolute
  top: 0
  width: 1668px

.rectangle-4097
  background-color: $catalina-blue
  border: 1px none
  height: 767px
  left: 201px
  position: absolute
  top: 164px
  width: 1441px

.ellipse-116
  background-color: $gold
  border: 0.98px none
  border-radius: 201px/201.5px
  height: 403px
  left: 1px
  position: absolute
  top: 163px
  transform: rotate(90.00deg)
  width: 402px

.ellipse-115
  height: 137px
  left: 718px
  position: absolute
  top: 794px
  width: 275px

.ellipse-114-1
  background-color: $carnation
  border: 0.98px none
  border-radius: 163.5px/164px
  height: 328px
  left: 1340px
  position: absolute
  top: 0
  transform: rotate(90.00deg)
  width: 327px

.rectangle-1513-1
  background-color: $torea-bay
  border: 1px none
  height: 334px
  left: 1308px
  position: absolute
  top: 597px
  width: 334px

.rectangle-4105
  height: 334px
  left: 1308px
  position: absolute
  top: 597px
  width: 334px

.sub-headings-95
  left: 955px
  letter-spacing: 0
  line-height: 61px
  position: absolute
  top: 401px
  width: 536px

.sub-headings-96
  left: 955px
  letter-spacing: 1.60px
  line-height: 18px
  position: absolute
  top: 361px
  white-space: nowrap

.sub-headings-97
  left: 955px
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 606px
  width: 536px

.mask-group-8
  height: 732px
  left: 335px
  position: absolute
  top: 275px
  width: 520px

.vector-21-1
  height: 1px
  left: 885px
  position: absolute
  top: 134px
  width: 64px

.our-name
  align-items: center
  display: flex
  flex-direction: column
  margin-right: 27.0px
  margin-top: 108px
  min-height: 244px
  width: 749px

.our-name-1
  letter-spacing: 1.60px
  line-height: 18px
  margin-right: 6.0px
  margin-top: -1px
  min-height: 18px
  min-width: 103px
  text-align: center
  white-space: nowrap

.polis-means-city-i
  letter-spacing: 0
  line-height: 50px
  margin-right: 6.0px
  margin-top: 14px
  min-height: 100px
  min-width: 743px
  text-align: center

.sub-headings-98
  letter-spacing: 0
  line-height: 30px
  margin-right: 7.0px
  margin-top: 22px
  min-height: 90px
  text-align: center
  width: 530px

.our-mission
  align-items: center
  display: flex
  margin-right: 3.46px
  margin-top: 108px
  min-width: 1172px

.group-39349
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 1.5px
  min-height: 446px
  position: relative
  width: 527px

.group-39343
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 336px
  width: 533px

.sub-headings-94
  letter-spacing: 1.60px
  line-height: 18px
  margin-top: -1px
  min-height: 18px
  white-space: nowrap

.sub-headings-99
  letter-spacing: 0
  line-height: 50px
  margin-top: 20px
  min-height: 150px
  width: 527px

.sub-headings-100
  letter-spacing: 0
  line-height: 30px
  margin-top: 28px
  min-height: 120px
  width: 527px

.group-39544
  background-size: 100% 100%
  height: 625px
  margin-left: 120px
  width: 525px

.our-story
  align-items: center
  display: flex
  margin-right: 0.36px
  margin-top: 147px
  min-width: 1174px

.mask-group-9
  height: 625px
  width: 574px

.group-39316
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 36.16px
  margin-left: 72px
  min-height: 485px
  position: relative
  width: 535px

.sub-headings-101
  letter-spacing: 0
  line-height: 50px
  margin-top: 20px
  min-height: 50px
  white-space: nowrap

.sub-headings-102
  letter-spacing: 0
  line-height: 30px
  margin-top: 34px
  min-height: 90px
  width: 527px

.line-455
  height: 1px
  margin-left: 0.37px
  margin-top: 36px
  width: 527px

.sub-headings-103
  letter-spacing: 0
  line-height: 30px
  margin-top: 33px
  min-height: 90px
  width: 527px

.meet-the-team
  align-items: flex-start
  border: 1px none
  display: flex
  height: 622px
  justify-content: flex-end
  margin-right: 1.0px
  margin-top: 394px
  min-width: 1171px

.overlap-group1-8
  height: 920px
  margin-top: -265px
  position: relative
  width: 1442px

.group-39247
  align-items: flex-end
  background-color: $catalina-blue
  display: flex
  height: 470px
  left: 0
  min-width: 1442px
  position: absolute
  top: 0

.e-container-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: -0.48px
  min-height: 470px
  width: 205px

.rectangle-4108-2
  height: 205px
  width: 205px

.ellipse-412-1
  height: 264px
  margin-left: 0
  margin-top: 1px
  width: 131px

.e-container-3
  height: 470px
  margin-bottom: -0.49px
  margin-left: 971px
  position: relative
  width: 264px

.ellipse-413-3
  background-color: $torea-bay-2
  border: 0.77px none
  border-radius: 131.45px/131.84px
  height: 264px
  left: 0
  position: absolute
  top: 207px
  transform: rotate(90.00deg)
  width: 263px

.rectangle-4114-2
  background-color: $torea-bay
  border: 0.62px none
  height: 208px
  left: 56px
  position: absolute
  top: 0
  width: 208px

.ellipse-419-1
  background-color: $gold
  border: 1px none
  border-radius: 102.9px
  height: 206px
  left: 56px
  position: absolute
  top: 0
  width: 206px

.sub-headings-104
  left: 450px
  letter-spacing: 0
  line-height: 50px
  position: absolute
  text-align: center
  top: 164px
  white-space: nowrap
  width: 540px

.sub-headings-105
  left: 669px
  letter-spacing: 1.60px
  line-height: 18px
  position: absolute
  text-align: center
  top: 126px
  white-space: nowrap

.why-polis
  align-items: flex-start
  background-color: $zircon
  display: flex
  height: 1070px
  margin-top: 136px
  min-width: 1440px
  padding: 96.5px 132px

.flex-col-12
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 36.53px
  min-height: 508px
  width: 502px

.text
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 392px
  width: 502px

.why-polis-1
  letter-spacing: 0
  line-height: 61px
  margin-top: -1px
  min-height: 61px
  white-space: nowrap

.sub-headings-106
  letter-spacing: 0
  line-height: 30px
  margin-top: 31px
  min-height: 300px
  width: 498px

.primary-btn-30
  align-items: flex-start
  background-color: $torea-bay
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 1px
  margin-top: 52px
  min-width: 365px
  padding: 21px 40px

.button-text-32
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 285px
  text-align: center
  white-space: nowrap

.images
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 98px
  min-height: 800px
  padding: 0.0px 0
  width: 573px

.mask-group-10
  height: 379px
  width: 573px

.mask-group-11
  height: 379px
  margin-top: 41px
  width: 573px

