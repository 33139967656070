@import '../../variables'

.polis-faq
  align-items: flex-start
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 5024px
  overflow: hidden
  position: relative
  width: 1440px

.overlap-group5-2
  height: 1350px
  margin-left: -154.95px
  position: relative
  width: 1607px

.overlap-group3-2
  height: 640px
  left: 0
  position: absolute
  top: 94px
  width: 1595px

.have-questions-we-have-answers
  left: 580px
  letter-spacing: 0
  line-height: 50px
  position: absolute
  text-align: center
  top: 224px
  white-space: nowrap

.sub-headings-69
  left: 610px
  letter-spacing: 0
  line-height: 30px
  position: absolute
  text-align: center
  top: 284px
  width: 530px

.frequently-asked-questions
  left: 730px
  letter-spacing: 1.60px
  line-height: 18px
  position: absolute
  text-align: center
  top: 192px
  white-space: nowrap

.rectangle-4114-1
  height: 205px
  left: 420px
  position: absolute
  top: 345px
  width: 205px

.rectangle-4364
  background-color: $torea-bay-2
  border: 1px none
  height: 640px
  left: 155px
  position: absolute
  top: 0
  width: 1440px

.group-39242
  align-items: flex-start
  display: flex
  height: 309px
  justify-content: flex-end
  left: 0
  min-width: 310px
  padding: 0 0.5px
  position: absolute
  top: 208px

.ellipse-413-1
  background-color: $gold
  border: 0.6px none
  border-radius: 154.49px/154.95px
  height: 310px
  margin-top: -0.46px
  transform: rotate(90.00deg)
  width: 309px

.ellipse-420
  background-color: $carnation
  border: 1px none
  border-radius: 102.9px
  height: 206px
  left: 1389px
  position: absolute
  top: 312px
  width: 206px

.have-questions-we-have-answers-1
  left: 626px
  letter-spacing: 0
  line-height: 61px
  position: absolute
  text-align: center
  top: 155px
  width: 499px

.sub-headings-70
  left: 806px
  letter-spacing: 1.60px
  line-height: 18px
  position: absolute
  text-align: center
  top: 125px
  white-space: nowrap

.nav-link-item
  left: 635px
  letter-spacing: 0
  line-height: 30px
  position: absolute
  text-align: center
  top: 291px
  width: 481px

.rectangle-4379
  background-color: $torea-bay
  border: 0.62px none
  height: 208px
  left: 155px
  position: absolute
  top: 0
  width: 208px

.rectangle-4376
  background-color: $catalina-blue
  border: 0.62px none
  height: 208px
  left: 1387px
  position: absolute
  top: 0
  width: 208px

.rectangle-4377
  height: 208px
  left: 1387px
  position: absolute
  top: 0
  width: 208px

.group-39241
  align-items: flex-start
  display: flex
  height: 208px
  justify-content: flex-end
  left: 1387px
  min-width: 208px
  padding: 0 0.3px
  position: absolute
  top: 104px
  transform: rotate(90.00deg)

.ellipse-413-2
  background-color: $gold
  border: 0.6px none
  border-radius: 103.85px/104.16px
  height: 208px
  margin-top: -0.31px
  width: 208px

.rectangle-4378
  height: 208px
  left: 145px
  position: absolute
  top: 0
  width: 208px

.overlap-group4-2
  border-radius: 18px
  height: 808px
  left: 488px
  position: absolute
  top: 542px
  width: 774px

.group-39254
  align-items: center
  background-color: $white
  border: 0.5px solid
  border-color: $mystic
  border-radius: 18px
  box-shadow: 0px 5px 18px #1e24590d
  display: flex
  flex-direction: column
  left: 0
  min-height: 808px
  padding: 67.9px 60.0px
  position: absolute
  top: 0
  width: 774px

.line-458
  height: 1px
  margin-right: 2.0px
  margin-top: 34px
  width: 627px

.flex-row-20
  align-items: center
  align-self: flex-end
  display: flex
  margin-right: 0
  margin-top: 48px
  min-width: 640px

.heading-h2
  letter-spacing: 0
  line-height: 28px
  margin-bottom: 2.0px
  min-height: 28px
  white-space: nowrap
  width: 526px

.group-39
  height: 40px
  margin-left: 74px
  width: 40px

.line-459
  height: 1px
  margin-right: 2.0px
  margin-top: 45px
  width: 627px

.flex-row-21
  align-items: center
  align-self: flex-end
  display: flex
  margin-right: 0
  margin-top: 50px
  min-width: 640px

.line-46
  height: 1px
  margin-right: 2.0px
  margin-top: 43px
  width: 627px

.flex-row-22
  align-items: center
  align-self: flex-end
  display: flex
  margin-top: 48px
  min-width: 640px

.general-questions
  left: 138px
  letter-spacing: 0
  line-height: 61px
  position: absolute
  text-align: center
  top: 68px
  white-space: nowrap
  width: 499px

.overlap-group-container
  height: 2909px
  margin-top: 173px
  position: relative
  width: 1442px

.overlap-group1-5
  background-size: 100% 100%
  height: 1455px
  left: 0
  position: absolute
  top: 1454px
  width: 1440px

.group-39254-1
  align-items: center
  background-color: $white
  border: 0.5px solid
  border-color: $mystic
  border-radius: 18px
  box-shadow: 0px 5px 18px #1e24590d
  display: flex
  flex-direction: column
  left: 333px
  min-height: 1093px
  padding: 105.4px 60.0px
  position: absolute
  top: 183px
  width: 774px

.flex-row-23
  align-items: center
  align-self: flex-end
  display: flex
  margin-right: 0
  margin-top: 42px
  min-width: 640px

.heading-h2-1
  letter-spacing: 0
  line-height: 28px
  margin-bottom: 2.0px
  min-height: 28px
  white-space: nowrap
  width: 500px

.group-3924
  height: 40px
  margin-left: 100px
  width: 40px

.flex-row-24
  align-items: center
  align-self: flex-end
  display: flex
  margin-top: 43px
  min-width: 640px

.for-educators
  left: 471px
  letter-spacing: 0
  line-height: 61px
  position: absolute
  text-align: center
  top: 261px
  white-space: nowrap
  width: 499px

.overlap-group2-4
  background-size: 100% 100%
  height: 1455px
  left: 0
  position: absolute
  top: 0
  width: 1440px

.group-39256
  align-items: center
  background-color: $white
  border: 0.5px solid
  border-color: $mystic
  border-radius: 18px
  box-shadow: 0px 5px 18px #1e24590d
  display: flex
  flex-direction: column
  left: 333px
  min-height: 1116px
  padding: 105.4px 60.0px
  position: absolute
  top: 160px
  width: 774px

.for-learners
  left: 471px
  letter-spacing: 0
  line-height: 61px
  position: absolute
  text-align: center
  top: 243px
  white-space: nowrap
  width: 499px

