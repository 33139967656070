@import '../../variables'

.group-393-1
  align-items: center
  background-color: $white
  border: 1px solid
  border-color: $seashell
  border-radius: 24px
  box-shadow: 0px 2.15px 10.77px #1e245914
  display: flex
  height: 240px
  margin-top: 24px
  min-width: 574px
  padding: 25px 38px

.group-38028-7
  background-color: #6935ff1a
  border: 0.7px solid
  border-color: $torea-bay-3
  border-radius: 15.46px
  height: 86px
  margin-bottom: 42.0px
  width: 86px

.sub-headings-container-9
  align-self: flex-end
  height: 164px
  margin-left: 26px
  position: relative
  width: 383px

.sub-headings-153
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 44px
  width: 383px

.sub-headings-154
  left: 0
  letter-spacing: 0
  line-height: 34px
  position: absolute
  top: 0

.group-393-1.group-39308
  margin-top: unset

.group-393-1.group-39308 .group-38028-7
  background-color: $gold-2
  border: unset
  border-color: unset

.group-393-1.group-393 .group-38028-7
  background-color: $carnation-2
  border: unset
  border-color: unset

