.group-39358
  align-items: flex-start
  display: flex
  margin-left: -1px
  min-width: 1172px

.group-39329
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 590px
  width: 373px

.group-39330
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 590px
  margin-left: 26px
  width: 374px

.group-39331
  background-size: 100% 100%
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 590px
  margin-left: 27px
  width: 372px

.group-39358.team-cards
  height: 590px
  left: 133px
  margin-left: unset
  position: absolute
  top: 330px

