@import '../../variables'

.footer-1
  align-items: center
  border: 1px none
  display: flex
  flex-direction: column
  left: 0
  min-height: 592px
  padding: 1px 0
  position: absolute
  top: 1790px
  width: 1440px

.line-443-1
  height: 1px
  margin-right: 0.04px
  width: 1440px

.flex-row-11
  align-items: flex-start
  display: flex
  height: 335px
  margin-top: 79px
  min-width: 1174px

.group-container-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 0
  min-height: 328px
  width: 369px

.group-39615-1
  align-items: flex-start
  display: flex
  height: 231px
  margin-left: 0.1px
  min-width: 369px

.flex-col-2
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: -1px
  min-height: 232px
  width: 186px

.sub-headings-42
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  min-height: 20px
  white-space: nowrap

.group-39614-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 40px
  min-height: 172px
  width: 186px

.sub-headings-26
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.sub-headings-27
  letter-spacing: 0
  line-height: 20px
  margin-bottom: 0
  margin-top: 24px
  min-height: 20px
  white-space: nowrap

.sub-headings-43
  letter-spacing: 0
  line-height: 20px
  margin-top: 24px
  min-height: 40px
  width: 81px

.group-39613-1
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-bottom: 4.8px
  margin-left: 40px
  min-height: 108px
  width: 147px

.group-2793-1
  align-items: flex-start
  display: flex
  margin-top: 68px
  min-width: 255px
  position: relative

.group-2787-1
  align-items: flex-start
  background-color: $blue
  border-radius: 80px
  display: flex
  height: 29px
  justify-content: flex-end
  min-width: 29px
  padding: 6.4px 10.0px

.icon-facebook-1
  height: 16px
  width: 9px

.group-78-1
  align-items: flex-end
  background-color: $navy-blue
  border-radius: 80px
  display: flex
  height: 29px
  justify-content: flex-end
  margin-left: 16px
  min-width: 29px
  padding: 7.4px 7.1px

.icon-linkedin-1
  height: 14px
  width: 15px

.group-39616-1
  align-items: flex-start
  background-color: $torea-bay
  background-size: 100% 100%
  border-radius: 13px
  display: flex
  flex-direction: column
  margin-left: 348px
  min-height: 335px
  padding: 54.9px 45.0px
  width: 457px

.subscribe-to-our-newsletter-2
  letter-spacing: 0
  line-height: 34px
  margin-bottom: 0
  min-height: 34px
  white-space: nowrap

.overlap-group-5
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 44px
  min-width: 364px
  padding: 19.0px 24px

.sub-headings-44
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.primary-btn-15
  align-items: flex-start
  background-color: $white
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-left: 0.34px
  margin-top: 22px
  min-width: 364px
  padding: 21px 143px

.button-text-15
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 78px
  text-align: center
  white-space: nowrap

.line-444-1
  height: 1px
  margin-right: 0.32px
  margin-top: 70px
  width: 1174px

.copyright-marketin-1
  align-self: flex-start
  letter-spacing: 0
  line-height: 30px
  margin-bottom: 0
  margin-left: 133px
  margin-top: 32px
  min-height: 30px
  white-space: nowrap

.footer-1.footer-2,
.footer-1.footer-3,
.footer-1.footer-5,
.footer-1.footer-6,
.footer-1.footer-7,
.footer-1.footer-8
  left: unset
  position: unset
  top: unset

.footer-1.footer-3 .group-78-1,
.footer-1.footer-4 .group-78-1,
.footer-1.footer-5 .group-78-1,
.footer-1.footer-6 .group-78-1,
.footer-1.footer-8 .group-78-1
  justify-content: unset

.footer-1.footer-4
  top: 1658px

