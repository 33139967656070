@import '../../variables'

.blog-v1-card-3
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 933px
  min-height: 425px
  position: absolute
  top: 569px
  width: 374px

.overlap-group5
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38096-1
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 28px
  min-height: 149px
  position: relative
  width: 376px

.address-2
  letter-spacing: 0
  line-height: 34px
  margin-top: 16px
  min-height: 68px
  width: 374px

.blog-v1-card-3.blog-v1-card-3-1
  left: unset
  margin-top: 43px
  position: unset
  top: unset

.blog-v1-card-3.blog-v1-card-3-2
  top: 1289px

.blog-v1-card-3.blog-v1-card-3-3
  left: 0
  top: 0

.blog-v1-card-3.blog-v1-card-4-1
  left: unset
  position: unset
  top: unset

