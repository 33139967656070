@import '../../variables'

.blog-v1-card-1
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  left: 133px
  min-height: 425px
  position: absolute
  top: 569px
  width: 374px

.overlap-group1-1
  background-color: $mist-gray
  background-size: 100% 100%
  border-radius: 13.83px
  height: 250px
  width: 374px

.group-38094
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 28px
  min-height: 147px
  position: relative
  width: 376px

.address
  letter-spacing: 0
  line-height: 34px
  margin-top: 16px
  min-height: 68px
  width: 374px

.blog-v1-card-1.blog-v1-card-1-1
  left: unset
  margin-left: 0.03px
  margin-top: 51px
  position: unset
  top: unset

.blog-v1-card-1.blog-v1-card-1-2
  top: 1289px

.blog-v1-card-1.blog-v1-card-1-3
  left: unset
  position: unset
  top: unset

