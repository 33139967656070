@import '../../variables'

.blog-post
  align-items: center
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 7990px
  overflow: hidden
  position: relative
  width: 1440px

.frame-35996-14
  align-items: flex-start
  align-self: flex-start
  border: 1px none
  display: flex
  height: 20px
  justify-content: flex-end
  margin-left: 133.44px
  margin-top: 23px
  min-width: 157px
  padding: 0 0.0px

.group-1
  align-self: center
  height: 10px
  margin-top: 0
  width: 5px

.sub-headings-66
  letter-spacing: 0
  line-height: 20px
  margin-left: 13px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.secondary-btn
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 10px
  display: flex
  height: 53px
  justify-content: flex-end
  margin-right: 615.83px
  margin-top: 52px
  min-width: 158px
  padding: 15px 24.0px

.icon-search
  align-self: center
  height: 19px
  margin-bottom: 1.0px
  width: 19px

.button-text-23
  letter-spacing: 0.36px
  line-height: 20px
  margin-left: 8px
  min-height: 20px
  min-width: 83px
  text-align: center
  white-space: nowrap

.what-is-personal-education-1
  letter-spacing: 0
  line-height: 61px
  margin-top: 33px
  min-height: 61px
  white-space: nowrap
  width: 772px

.we-need-to-figure-ou
  letter-spacing: 0
  line-height: 30px
  margin-right: 0.49px
  margin-top: 33px
  min-height: 60px
  width: 772px

.flex-row-19
  align-items: center
  display: flex
  margin-right: 575.67px
  margin-top: 19px
  min-width: 196px

.mask-group-2
  height: 55px
  width: 55px

.frame-35996-15
  align-items: flex-start
  border: 1px none
  display: flex
  height: 20px
  margin-bottom: 1.33px
  margin-left: 14px
  min-width: 127px

.sub-headings-67
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.blog-image-7
  background-color: $mist-gray
  background-size: 100% 100%
  border: 2.11px none
  border-radius: 56.3px
  height: 713px
  margin-right: 0.13px
  margin-top: 95px
  width: 1173px

.group-7127
  align-items: flex-start
  display: flex
  height: 1404px
  margin-left: 0.03px
  margin-top: 89px
  min-width: 774px

.rich-text
  align-items: flex-start
  border: 1px none
  display: flex
  flex-direction: column
  min-height: 1404px
  width: 774px

.our-goal-is-to-provi
  letter-spacing: 0
  line-height: 50px
  margin-top: -1px
  min-height: 250px
  width: 772px

.overlap-group6-1
  height: 135px
  margin-top: 1px
  position: relative
  width: 772px

.margin-1
  border: 1px solid
  border-color: $white
  height: 16px
  left: 0
  position: absolute
  top: 0
  width: 24px

.though-the-phrase-p,
.in-recent-years-tho,
.though-well-meaning,
.personalized-learnin
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 15px
  width: 772px

.margin-2
  border: 1px solid
  border-color: $white
  height: 32px
  margin-top: 1px
  width: 32px

.overlap-group7-1
  height: 73px
  position: relative
  width: 772px

.margin-3
  border: 1px solid
  border-color: #f9f9f9
  height: 40px
  left: 0
  position: absolute
  top: 0
  width: 48px

.more-than-just-buzzwords
  left: 0
  letter-spacing: 0
  line-height: 34px
  position: absolute
  top: 39px
  white-space: nowrap
  width: 772px

.overlap-group5-1
  height: 315px
  margin-top: 1px
  position: relative
  width: 772px

.margin
  border: 1px solid
  border-color: $white
  height: 16px
  left: 0
  position: absolute
  top: 0
  width: 16px

.overlap-group-14
  align-items: flex-start
  background-color: $white
  background-size: 100% 100%
  border: 1px solid
  border-color: #e1e1e1
  border-radius: 12px
  display: flex
  height: 300px
  margin-left: -0.97px
  min-width: 774px
  padding: 57.8px 86.8px

.instruction-in-whic
  letter-spacing: 0
  line-height: 32px
  min-height: 174px
  width: 600px

.overlap-group2-3
  height: 298px
  margin-top: 1px
  position: relative
  width: 772px

.margin-4
  border: 1px solid
  border-color: $white
  height: 29px
  left: 0
  position: absolute
  top: 0
  width: 56px

.such-a-broad-definit
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 28px
  width: 772px

.margin24px
  border: 1px solid
  border-color: $white
  height: 24px
  margin-top: 1px
  width: 24px

.overlap-group9-1
  height: 383px
  position: relative
  width: 772px

.image
  height: 375px
  left: 0
  position: absolute
  top: 0
  width: 772px

.margin-5
  border: 1px solid
  border-color: $white
  height: 8px
  left: 0
  position: absolute
  top: 375px
  width: 24px

.overlap-group-13
  height: 89px
  position: relative
  width: 772px

.margin48px
  border: 1px solid
  border-color: $white
  height: 56px
  left: 0
  position: absolute
  top: 0
  width: 56px

.a-messy-reality,
.personalized-education
  left: 0
  letter-spacing: 0
  line-height: 34px
  position: absolute
  top: 55px
  white-space: nowrap
  width: 772px

.overlap-group4-1
  height: 1305px
  margin-top: 1px
  position: relative
  width: 772px

.image-1
  height: 375px
  margin-top: 1px
  width: 772px

.overlap-group1-4
  height: 883px
  margin-top: 1px
  position: relative
  width: 772px

.span2
  color: $carnation
  font-family: $font-family-lato
  font-size: $font-size-xxl
  font-weight: 700

.group-39720
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 3240px
  min-height: 415px
  width: 774px

.line-477
  height: 1px
  width: 774px

.group-container-9
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $athens-gray
  border-radius: 24px
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  display: flex
  height: 338px
  margin-top: 76px
  min-width: 774px
  padding: 57.0px 46.5px

.mask-group-3
  height: 222px
  width: 222px

.group-39499
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-left: 34px
  margin-top: 4.22px
  min-height: 171px
  width: 423px

.group-39629
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 48px
  width: 146px

.name
  letter-spacing: 0
  line-height: 22px
  margin-top: -1px
  min-height: 22px
  white-space: nowrap

.sub-headings-68
  letter-spacing: 0
  line-height: 18px
  margin-top: 8px
  min-height: 18px
  white-space: nowrap

.lorem-ipsum-dolor-si-1
  letter-spacing: 0
  line-height: 30px
  margin-top: 8px
  min-height: 60px
  width: 423px

.group-2793-9
  align-items: flex-start
  display: flex
  margin-top: 29px
  min-width: 134px

.group-2787-9
  align-items: flex-start
  background-color: $blue
  border-radius: 122.92px
  box-shadow: 0px 4.01px 12.96px #1574ef36
  display: flex
  height: 25px
  justify-content: flex-end
  margin-top: 0
  min-width: 25px
  padding: 5.5px 8.7px

.icon-facebook-9
  height: 14px
  width: 8px

.group-75-2
  align-items: flex-end
  background-color: $azure-radiance
  border-radius: 122.92px
  box-shadow: 0px 4.01px 12.96px #1abaff3d
  display: flex
  height: 25px
  margin-left: 11px
  min-width: 25px
  padding: 7.0px 5.8px

.vector-18
  height: 11px
  width: 13px

.group-73-1
  align-items: flex-end
  align-self: flex-end
  background: radial-gradient(50% 50% at 50% 50%, rgb(250, 143, 33) 9%, rgb(234.81, 36.2, 130.28) 78%)
  border-radius: 122.92px
  box-shadow: 0px 4.91px 12.96px #fa0e5a24
  display: flex
  height: 25px
  margin-left: 11px
  min-width: 25px
  padding: 5.1px 5.0px

.vector-19
  height: 15px
  width: 15px

.group-78-3
  align-items: center
  background-color: $navy-blue
  border-radius: 122.92px
  box-shadow: 0px 4.01px 12.96px #147ee73b
  display: flex
  height: 25px
  justify-content: flex-end
  margin-left: 11px
  min-width: 25px
  padding: 0 6.1px

.icon-linkedin-9
  height: 12px
  width: 13px

.overlap-group14
  align-items: flex-start
  background-color: $zircon
  display: flex
  height: 836px
  justify-content: flex-end
  margin-left: 1.0px
  margin-top: 97px
  min-width: 1451px
  padding: 122px 136px
  position: relative

.flex-col-10
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 6px
  min-height: 526px
  position: relative
  width: 374px

.latest-articles
  letter-spacing: 0
  line-height: 50px
  min-height: 50px
  white-space: nowrap

.flex-col-11
  align-items: flex-end
  display: flex
  flex-direction: column
  margin-left: 26px
  min-height: 532px
  position: relative
  width: 374px

