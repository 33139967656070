@import '../../variables'

.polis-contact
  align-items: flex-start
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 1586px
  overflow: hidden
  position: relative
  width: 1440px

.overlap-group4-5
  height: 994px
  position: relative
  width: 1440px

.follow-us
  left: 133px
  letter-spacing: 0
  line-height: 34px
  position: absolute
  top: 923px
  white-space: nowrap

.contact-form
  align-items: center
  background-color: $zircon
  display: flex
  height: 900px
  justify-content: flex-end
  left: 0
  min-width: 1440px
  position: absolute
  top: 94px

.group-39523
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-bottom: 192.0px
  min-height: 264px
  width: 533px

.sub-headings-108
  letter-spacing: 1.60px
  line-height: 18px
  margin-top: -1px
  min-height: 18px
  white-space: nowrap

.sub-headings-container-2
  height: 226px
  margin-top: 20px
  position: relative
  width: 527px

.sub-headings-109
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 136px
  width: 494px

.sub-headings-110
  left: 0
  letter-spacing: 0
  line-height: 50px
  position: absolute
  top: 0
  width: 527px

.overlap-group3-5
  align-self: flex-end
  height: 900px
  margin-bottom: 0
  margin-left: 39px
  position: relative
  width: 735px

.rectangle-4097-1
  background-color: $torea-bay
  border: 1px none
  box-shadow: 0px 4px 22px #080f3408 , 0px -3px 8px #14132a03
  height: 900px
  left: 274px
  position: absolute
  top: 0
  width: 461px

.rectangle-4032-1
  height: 290px
  left: 445px
  position: absolute
  top: 610px
  width: 290px

.ellipse-407
  height: 318px
  left: 415px
  position: absolute
  top: 0
  width: 318px

.group-38113
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 0
  min-height: 739px
  padding: 62.9px 0
  position: absolute
  top: 80px
  width: 600px

.group-37882
  align-items: flex-start
  display: flex
  height: 342px
  margin-right: 0
  min-width: 490px

.group-container-12
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 342px
  position: relative
  width: 232px

.group-37940
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-top: 30px
  min-height: 94px
  width: 232px

.sub-headings-107
  letter-spacing: 0
  line-height: 20px
  margin-top: -1px
  min-height: 20px
  white-space: nowrap

.group-3-1
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 62px
  margin-top: 13px
  min-width: 230px
  padding: 19px 20.7px

.select-service
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap
  width: 111px

.vector-23
  align-self: center
  height: 5px
  margin-bottom: 2.0px
  margin-left: 65px
  width: 11px

.group-container-13
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 28px
  min-height: 342px
  position: relative
  width: 232px

.group-37939
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 0
  margin-top: 30px
  min-height: 94px
  width: 232px

.budget
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap
  width: 57px

.vector-24
  align-self: center
  height: 5px
  margin-bottom: 2.0px
  margin-left: 119px
  width: 11px

.overlap-group1-9
  align-items: flex-start
  background-color: $white
  border: 1px solid
  border-color: $snuff
  border-radius: 10px
  display: flex
  height: 147px
  margin-top: 30px
  min-width: 490px
  padding: 15.3px 24px

.describe-your-project
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.rectangle-15
  height: 91px
  left: 131px
  position: absolute
  top: 650px
  width: 390px

.group-38121
  align-items: center
  display: flex
  height: 48px
  left: 155px
  min-width: 275px
  position: absolute
  top: 671px

.group-38117
  align-items: flex-end
  background-color: #103da026
  border-radius: 13.74px
  display: flex
  height: 48px
  justify-content: flex-end
  min-width: 48px
  padding: 13.0px 10.2px

.overlap-group-16
  align-items: flex-start
  background-size: 100% 100%
  display: flex
  height: 22px
  min-width: 28px

.vector-25
  height: 11px
  width: 28px

.heading-h2-3
  letter-spacing: 0.36px
  line-height: 20px
  margin-bottom: 2.0px
  margin-left: 26px
  min-height: 20px
  min-width: 199px
  white-space: nowrap

