@import '../../variables'

.polis-how-it-works
  align-items: flex-start
  background-color: $white
  border: 1px none
  display: flex
  flex-direction: column
  height: 5066px
  overflow: hidden
  width: 1440px

.overlap-group9-2
  height: 969px
  position: relative
  width: 1440px

.hero-content
  align-items: flex-start
  display: flex
  height: 875px
  left: 132px
  min-width: 1307px
  padding: 0.0px 0
  position: absolute
  top: 94px

.group-37862
  align-items: flex-start
  align-self: center
  display: flex
  flex-direction: column
  margin-top: 82.52px
  min-height: 626px
  position: relative
  width: 530px

.sub-headings-72
  letter-spacing: 1.60px
  line-height: 18px
  margin-left: 0.22px
  margin-top: -1px
  min-height: 18px
  white-space: nowrap

.sub-headings-73
  letter-spacing: 0
  line-height: 61px
  margin-left: 0.6px
  margin-top: 30px
  min-height: 183px
  width: 523px

.sub-headings-74
  letter-spacing: 0
  line-height: 30px
  margin-left: 0.6px
  margin-top: 34px
  min-height: 90px
  width: 523px

.group-37859
  align-items: center
  display: flex
  height: 104px
  margin-left: 0.22px
  margin-top: 46px
  min-width: 234px

.group-container-10
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 104px
  width: 24px

.group-3
  height: 24px
  width: 24px

.group-4
  height: 24px
  margin-top: 15px
  width: 24px

.group-5
  height: 24px
  margin-top: 17px
  width: 24px

.sub-headings-container
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 13px
  min-height: 100px
  width: 191px

.sub-headings-75
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  white-space: nowrap

.sub-headings-76
  letter-spacing: 0
  line-height: 20px
  margin-top: 19px
  min-height: 20px
  white-space: nowrap

.sub-headings-77
  letter-spacing: 0
  line-height: 20px
  margin-top: 21px
  min-height: 20px
  white-space: nowrap

.overlap-group8-1
  height: 875px
  margin-left: 45px
  position: relative
  width: 733px

.rectangle-1534
  background-color: $torea-bay
  border: 1.45px none
  height: 259px
  left: 0
  position: absolute
  top: 84px
  width: 286px

.rectangle-1513
  background-color: $torea-bay
  border: 1px none
  height: 258px
  left: 475px
  position: absolute
  top: 513px
  width: 258px

.rectangle-1512
  background-color: $catalina-blue
  border: 1px none
  height: 513px
  left: 286px
  position: absolute
  top: 0
  width: 447px

.ellipse-119
  background-color: $gold
  border: 1px none
  border-radius: 130.63px
  height: 261px
  left: 420px
  position: absolute
  top: 382px
  width: 261px

.rectangle-1514
  height: 269px
  left: 286px
  position: absolute
  top: 0
  width: 269px

.mask-group-4
  height: 709px
  left: 89px
  position: absolute
  top: 166px
  width: 504px

.nav-indicator
  height: 1px
  left: 807px
  position: absolute
  top: 18px
  width: 116px

.using-polis
  align-items: center
  align-self: center
  display: flex
  flex-direction: column
  margin-left: 4.35px
  margin-top: 124px
  min-height: 1639px
  padding: 0 0.0px
  width: 1288px

.sub-headings-78
  letter-spacing: 1.60px
  line-height: 18px
  margin-right: 3.85px
  margin-top: -1px
  min-height: 18px
  text-align: center
  white-space: nowrap
  width: 167px

.sub-headings-79
  letter-spacing: 0
  line-height: 50px
  margin-right: 3.35px
  margin-top: 18px
  min-height: 164px
  text-align: center
  width: 681px

.group-container-11
  align-items: center
  align-self: flex-start
  display: flex
  margin-top: 29px
  min-width: 1219px

.overlap-group7-2
  height: 630px
  position: relative
  width: 633px

.group-38126
  height: 153px
  left: 0
  position: absolute
  top: 0
  width: 153px

.rectangle-4425
  background-color: $mist-gray
  border: 1px none
  border-radius: 24px
  height: 582px
  left: 46px
  position: absolute
  top: 48px
  width: 587px

.mask-group-5
  height: 582px
  left: 46px
  position: absolute
  top: 48px
  width: 587px

.group-39364
  align-items: flex-start
  display: flex
  flex-direction: column
  margin-left: 69px
  margin-top: 48.8px
  min-height: 322px
  width: 518px

.sub-headings-container-1
  align-self: flex-end
  height: 259px
  margin-top: -1px
  position: relative
  width: 474px

.sub-headings-80
  left: 0
  letter-spacing: 0
  line-height: 50px
  position: absolute
  top: 0
  width: 474px

.sub-headings-81
  left: 0
  letter-spacing: 0
  line-height: 30px
  position: absolute
  top: 79px
  width: 474px

.primary-btn-23
  align-items: flex-start
  border: 3px solid
  border-color: $torea-bay
  border-radius: 10px
  display: flex
  height: 64px
  margin-top: 1px
  min-width: 269px
  padding: 19px 40px

.button-text-25
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 189px
  text-align: center
  white-space: nowrap

.overlap-group-container-1
  align-items: center
  align-self: flex-end
  display: flex
  margin-right: 4.0px
  margin-top: 121px
  min-width: 1253px

.overlap-group1-6
  height: 336px
  margin-bottom: 46.05px
  position: relative
  width: 547px

.primary-btn-24
  align-items: flex-start
  border: 3px solid
  border-color: $torea-bay
  border-radius: 10px
  display: flex
  height: 64px
  left: 0
  min-width: 212px
  padding: 19px 40px
  position: absolute
  top: 272px

.button-text-26
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 132px
  text-align: center
  white-space: nowrap

.group-38016
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 17px
  min-height: 284px
  position: absolute
  top: 0
  width: 531px

.sub-headings-82
  letter-spacing: 0
  line-height: 50px
  margin-top: -1px
  min-height: 50px
  white-space: nowrap

.sub-headings-83
  letter-spacing: 0
  line-height: 30px
  margin-left: 0.41px
  margin-top: 54px
  min-height: 180px
  width: 518px

.overlap-group6-2
  height: 661px
  margin-left: 54px
  position: relative
  width: 652px

.ellipse-110
  background-color: #0061ff
  border: 1px none
  border-radius: 124.69px
  height: 249px
  left: 403px
  opacity: 0.08
  position: absolute
  top: 412px
  width: 249px

.mask-group-6
  height: 582px
  left: 0
  position: absolute
  top: 0
  width: 587px

.overlap-group10
  height: 2250px
  margin-top: 84px
  position: relative
  width: 1711px

.overlap-group4-3
  height: 986px
  left: 0
  position: absolute
  top: 0
  width: 1709px

.rectangle-4051
  height: 986px
  left: 0
  position: absolute
  top: 0
  width: 1440px

.overlap-group-15
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 132px
  min-height: 406px
  padding: 63.0px 26.3px
  position: absolute
  top: 317px
  width: 374px

.group-38028
  background-color: $carnation-2
  border-radius: 15.46px
  height: 86px
  margin-top: 1px
  width: 86px

.sub-headings-84
  letter-spacing: 0
  line-height: 34px
  margin-top: 23px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 233px

.sub-headings-71
  align-self: flex-start
  letter-spacing: 0
  line-height: 30px
  margin-top: 10px
  min-height: 90px
  text-align: center
  width: 321px

.line-451
  height: 1px
  margin-top: 35px
  width: 109px

.overlap-group1-7
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 533px
  min-height: 406px
  padding: 63.0px 26.3px
  position: absolute
  top: 317px
  width: 374px

.group-38028-1
  background-color: $torea-bay-3
  border-radius: 15.46px
  height: 86px
  margin-top: 1px
  width: 86px

.sub-headings-85
  letter-spacing: 0
  line-height: 34px
  margin-right: 1.0px
  margin-top: 23px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 216px

.overlap-group2-5
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 934px
  min-height: 406px
  padding: 63.0px 26.3px
  position: absolute
  top: 317px
  width: 374px

.group-38028-2
  background-color: $gold-2
  border-radius: 15.46px
  height: 86px
  margin-top: 1px
  width: 86px

.sub-headings-86
  letter-spacing: 0
  line-height: 34px
  margin-right: 1.0px
  margin-top: 23px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 206px

.overlap-group3-3
  align-items: center
  background-size: 100% 100%
  display: flex
  flex-direction: column
  left: 1335px
  min-height: 406px
  padding: 63.0px 26.3px
  position: absolute
  top: 317px
  width: 374px

.team-work
  letter-spacing: 0
  line-height: 34px
  margin-top: 23px
  min-height: 34px
  text-align: center
  white-space: nowrap
  width: 105px

.sub-headings-87
  left: 457px
  letter-spacing: 0
  line-height: 50px
  position: absolute
  text-align: center
  top: 121px
  width: 527px

.overlap-group5-3
  height: 1025px
  left: 0
  position: absolute
  top: 902px
  width: 1614px

.rectangle-3859
  background-color: $torea-bay
  border: 1px none
  height: 671px
  left: 0
  position: absolute
  top: 84px
  width: 1440px

.ellipse-118
  background-color: $carnation
  border: 0.98px none
  border-radius: 271.06px
  height: 542px
  left: 1072px
  position: absolute
  top: 483px
  transform: rotate(90.00deg)
  width: 542px

.rectangle-3895
  background-color: $gold
  border: 1px none
  height: 672px
  left: 0
  position: absolute
  top: 84px
  width: 508px

.e-book-2
  background-size: 100% 100%
  border: 0.91px none
  box-shadow: 0px 12.92px 31.37px #14132a0d , 0px 5.54px 22.14px #4939ff08
  height: 643px
  left: 195px
  position: absolute
  top: 0
  width: 423px

.group-39599
  align-items: flex-start
  display: flex
  flex-direction: column
  left: 707px
  min-height: 516px
  position: absolute
  top: 127px
  width: 547px

.sub-headings-88
  letter-spacing: 0
  line-height: 50px
  margin-top: -1px
  min-height: 150px
  width: 543px

.sub-headings-89
  letter-spacing: 0
  line-height: 30px
  margin-top: 42px
  min-height: 120px
  width: 527px

.primary-btn-25
  align-items: flex-start
  background-color: $white
  border: 1px none
  border-radius: 10px
  display: flex
  height: 64px
  margin-top: 49px
  min-width: 204px
  padding: 21px 40px

.button-text-27
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 124px
  text-align: center
  white-space: nowrap

.primary-btn-26
  align-items: flex-start
  border: 3px solid
  border-color: $white
  border-radius: 10px
  display: flex
  height: 64px
  margin-top: 28px
  min-width: 204px
  padding: 19px 47.5px

.button-text-28
  letter-spacing: 0
  line-height: 20px
  min-height: 20px
  min-width: 109px
  text-align: center
  white-space: nowrap

