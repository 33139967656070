@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css")
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,500|Literata:700")
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6315f36fa6fbd0d14fab4909.6315f370d90b3a5e2f762690.lJD3gvf.rsp.png")





.screen a
  display: contents
  text-decoration: none

.container-center-horizontal
  display: flex
  flex-direction: row
  justify-content: center
  pointer-events: none
  width: 100%

.container-center-horizontal > *
  flex-shrink: 0
  pointer-events: auto

*
  box-sizing: border-box